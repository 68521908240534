import PropTypes from "prop-types";
import { format } from "date-fns";

export const DateCell = ({ value }) => {
  if (!value) return null;
  return format(new Date(value), "dd.MM.yyyy kk:mm");
};

DateCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DateCell.defaultProps = {
  value: null,
};
