import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RCPagination from "rc-pagination";
import localeEnUs from "enUsLocale";
import ArrowBack from "@mui/icons-material/ArrowBackIosNewRounded";
import More from "@mui/icons-material/MoreHorizRounded";
import ArrowForward from "@mui/icons-material/ArrowForwardIosRounded";
import { Box, MenuItem } from "@mui/material";
import styled from "@emotion/styled";
import { IconButton, Select } from "components";
import { TableCell } from "./TableCell";
import { TableRow } from "./TableRow";
import { PAGES_COUNT_OPTIONS } from "../config";
import "./rc-pagination.css";

const Count = styled.span`
  cursor: auto;
  position: relative;
  top: 1px;
  margin-right: 5px;
`;
const CountText = styled.span`
  margin-right: 3px;
  margin-left: 3px;
`;

const StyledTableCell = styled(TableCell)`
  padding: 2px 16px;
  height: 40px;
`;

export const Pagination = ({
  pageSize,
  setPageSize,
  pageIndex,
  gotoPage,
  pageCount,
}) => {
  const location = useLocation();
  const handleChange = (e) => {
    setPageSize(Number(e.target.value));
  };

  const handleClickTotalCount = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const renderNextIcon = (props) => (
    <>
      <Count onClick={handleClickTotalCount}>
        <CountText>of</CountText> {Math.ceil(pageCount / pageSize)}
      </Count>
      <IconButton
        {...props}
        disableRipple
        sx={{ width: "24px", height: "24px" }}
      >
        <ArrowForward sx={{ fontSize: "13px" }} />
      </IconButton>
    </>
  );

  const currentIndex = pageIndex + 1;
  const gotoPageHandler = (value) => gotoPage(value - 1);

  const getLocale = () => {
    const { i18n } = useTranslation();
    switch (i18n.language) {
      case "en":
        return localeEnUs;
      default:
        return localeEnUs;
    }
  };

  const getText = () => {
    if (location.pathname === "/event-log") {
      return `of ${pageCount} item(s)`;
    }

    return "entries";
  };

  return (
    <TableRow>
      <StyledTableCell colSpan={999}>
        <Container display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            Show
            <Select
              sx={{ margin: "0 8px" }}
              variant="standard"
              value={pageSize}
              onChange={handleChange}
            >
              {PAGES_COUNT_OPTIONS.map((count) => (
                <MenuItem
                  disableRipple
                  value={count}
                  key={`count_option_${count}`}
                >
                  {count}
                </MenuItem>
              ))}
            </Select>
            {getText()}
          </Box>
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" mr="2px">
              Page
            </Box>
            {/* TODO: Consider own implementation */}
            <RCPagination
              locale={getLocale()}
              showLessItems
              current={currentIndex}
              total={pageCount}
              onChange={gotoPageHandler}
              pageSize={pageSize}
              prevIcon={
                <IconButton
                  disableRipple
                  sx={{ width: "24px", height: "24px" }}
                >
                  <ArrowBack sx={{ fontSize: "13px" }} />
                </IconButton>
              }
              nextIcon={renderNextIcon}
              jumpPrevIcon={
                <IconButton
                  disableRipple
                  sx={{ width: "24px", height: "24px" }}
                >
                  <More fontSize="13px" />
                </IconButton>
              }
              jumpNextIcon={
                <IconButton
                  disableRipple
                  sx={{ width: "24px", height: "24px" }}
                >
                  <More fontSize="13px" />
                </IconButton>
              }
            />
          </Box>
        </Container>
      </StyledTableCell>
    </TableRow>
  );
};

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
};

const Container = styled(Box)`
  font-weight: 400;
`;
