import { styled } from "@mui/system";

export const Wrapper = styled("div")`
  position: relative;
`;

export const Heading = styled("div")`
  cursor: pointer;
  border-radius: 0.4rem;
  position: relative;
  width: 100%;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  background-color: rgba(46, 56, 68, 0.1);
  min-height: 32px;

  ${({ connected, theme }) =>
    connected &&
    `
     path {
      fill: ${theme.palette.primary.main}
    }
  `}
`;

export const ConnectionText = styled("span")`
  color: ${({ theme }) => theme.palette.text.main};
  font-weight: 500;
  line-height: 1.6rem;
  font-size: 1.4rem;
  margin-left: 0.8rem;
`;

export const DropdownIcon = styled("div")`
  position: absolute;
  right: 0.8rem;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.2s ease-in-out;

  path {
    fill: ${({ theme }) => theme.palette.text.main}!important;
  }

  ${({ rotate }) =>
    rotate &&
    `
        transform: translateY(-50%) rotate(180deg);
    `}
`;

export const Content = styled("div")`
  background-color: #fff;
  padding: ${({ noPadding }) => (noPadding ? "0" : "1.6rem 1.65rem")};
  width: 100%;
`;
