import axios from "axios";

export const fetchEventLogs = ({
  filter = "",
  page = 0,
  limit = 10,
  sort = { field: "name", order: "ASC" },
} = {}) =>
  axios.get("/eventlog", {
    params: {
      filter,
      page: String(page),
      limit: String(limit),
      sort: JSON.stringify(sort),
    },
    headers: {
      "Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

export const exportEventLogsToCsv = (rowIds) =>
  axios.post("/eventlog/exportToCsv", rowIds);
