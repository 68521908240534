import React from "react";
import { BorderedSection } from "components/BorderedSection";
import { InputLabel, FormRow, TextInput } from "components/global/forms";
import { useSelector } from "react-redux";
import {
  selectIsXtmTokenWithoutErrors,
  selectModifyForm,
  selectProfileDataSharePoint,
} from "data";

import { useTranslation } from "react-i18next";
import { Workflows } from "./Workflows/Workflows";
import { XTMCustomers } from "./XTMCustomers";
import { XTMConnect } from "./XTMConnect";
import { HOT_FOLDER, WORKING_COPY } from "../constants";
import { handleUpdateState } from "../helpers";
import { XTMHotFolders } from "./XTMHotFolders";

export const XTMSettings = () => {
  const { t } = useTranslation();
  const form = useSelector(selectModifyForm);
  const isXtmTokenWithoutErrors = useSelector(selectIsXtmTokenWithoutErrors);
  const profileDataSharePoint = useSelector(selectProfileDataSharePoint);

  const shouldDisplayXTMSettingsSection =
    profileDataSharePoint.tokenID || form.sharePoint.isConnected;

  const shouldDisplayWorkingCopySettings =
    form.connectionType === WORKING_COPY &&
    (isXtmTokenWithoutErrors || form.xtm.isConnected);

  const shouldDisplayHotFolderSettings =
    form.connectionType === HOT_FOLDER &&
    (isXtmTokenWithoutErrors || form.xtm.isConnected);

  const handleChange = (ev) => {
    const { value } = ev.target;
    handleUpdateState(value, "xtm.checkProjectMinutes");
  };

  if (!shouldDisplayXTMSettingsSection) return null;

  return (
    <BorderedSection name={t("connectionProfiles.form.xtm.xtmSectionSettings")}>
      <XTMConnect />
      {shouldDisplayWorkingCopySettings && (
        <>
          <XTMCustomers />
          <Workflows />
          <FormRow contained group sx={{ marginTop: "2.5rem" }}>
            <InputLabel lead>
              {t("connectionProfiles.form.xtm.checkProjectsEveryMinutes.label")}
              *
            </InputLabel>
            <TextInput
              sx={{ maxWidth: "133px" }}
              value={form.xtm.checkProjectMinutes}
              onChange={handleChange}
            />
          </FormRow>
        </>
      )}
      {shouldDisplayHotFolderSettings && (
        <>
          <XTMCustomers selectIndividual />
          <XTMHotFolders />
        </>
      )}
    </BorderedSection>
  );
};
