import { STEP_TYPES } from "constants";

const getStepType = ({ form, step, workflow }) => {
  const workflowPreProcessingSteps =
    form.xtm.workflows.preProcessingSteps?.[workflow.id] ?? [];

  const workflowIgnoredSteps =
    form.xtm.workflows.ignoredSteps?.[workflow.id] ?? [];

  const workflowNonCatToolSteps =
    form.xtm.workflows.nonCatToolSteps?.[workflow.id] ?? [];

  if (workflowPreProcessingSteps.includes(step.id))
    return STEP_TYPES.PREPROCESSING;
  if (workflowIgnoredSteps.includes(step.id)) return STEP_TYPES.IGNORED;
  if (workflowNonCatToolSteps.includes(step.id)) return STEP_TYPES.NON_CAT_TOOL;
  return STEP_TYPES.NORMAL;
};

const getSteps = ({ workflow, form }) =>
  workflow.steps.map((step) => {
    const type = getStepType({ form, step, workflow });
    return {
      xtm_step_id: step.id.split("-")[0],
      name: step.name,
      order_number: step.orderNumber,
      type,
    };
  });

const getWorkflows = ({ form, workflowsMap }) =>
  form.xtm.workflows.workflowIds.map((workflowId) => {
    const workflow = workflowsMap.get(workflowId);
    const steps = getSteps({ workflow, form });
    return { xtm_workflow_id: workflow.id, name: workflow.name, steps };
  });

export const addConnectionProfileMapper = ({ form, workflowsMap }) => {
  const workflows = getWorkflows({ form, workflowsMap });

  return {
    integrated_system_name: "Microsoft SharePoint",
    connection_profile_name: form.connectionProfileName,
    connection_type: form.connectionType,
    connection_profile_status: form.active === true ? "ACTIVE" : "INACTIVE",
    client_id: form.clientAssigned,
    xtm_temp_credential: form.xtm.tokenID,
    sharepoint_temp_credential: form.sharePoint.tokenID,
    sharepoint_folder_path: form.sharePoint.sharepointFolderPath,
    xtm_customers_ids: form.xtm.customers,
    periodicity_of_refresh: Number(form.xtm.checkProjectMinutes),
    workflows,
    site_name: form.sharePoint.loginCredentials.sharePointSiteName,
  };
};
