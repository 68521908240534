import {
  DateCell,
  EditCell,
  StatusCell,
} from "components/TableComponent/cells";
import i18n from "config/i18n";

const { t } = i18n;

export const administratorColumns = [
  {
    Header: t("column.id"),
    accessor: "id",
    width: "160px",
  },
  {
    Header: t("column.administrators.email"),
    accessor: "email",
    width: "250px",
  },
  {
    Header: t("column.administrators.clientName"),
    accessor: "client",
    width: "200px",
  },
  {
    Header: t("column.status"),
    accessor: "status",
    width: "140px",
    Cell: StatusCell,
  },
  {
    Header: t("column.dateAdded"),
    accessor: "created_date",
    width: "250px",
    Cell: DateCell,
  },
  {
    Header: t("column.dateModified"),
    accessor: "modification_date",
    width: "250px",
    Cell: DateCell,
  },
  {
    Header: "",
    accessor: "edit",
    width: "50px",
    canSort: false,
    Cell: EditCell({
      tooltipContent: t("column.administrators.editTooltip"),
      redirectParams: {
        pathname: "/administrators/edit",
        searchParam: "id",
      },
    }),
  },
];
