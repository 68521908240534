import React, { useEffect, useState } from "react";
import StyledContainer from "components/global/Container";
import { ReactComponent as HelpIcon } from "assets/help-icon.svg";
import { ReactComponent as ReleaseIcon } from "assets/release.svg";
import { ReactComponent as LogoutIcon } from "assets/logout-icon.svg";
import { useSelector } from "react-redux";
import { selectAuthState } from "data";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { LocalStorageService } from "services";
import * as Styles from "./styles";

function Header() {
  const [version, setVersion] = useState();
  const authState = useSelector(selectAuthState);
  const { t } = useTranslation();

  const userAuthority = LocalStorageService.getUserAuthority();

  const link =
    userAuthority === "MAIN_ADMINISTRATOR"
      ? "/clients"
      : "/connection-profiles";

  const helpURL =
    "https://help.xtm.cloud/connectors/en/xtm-connect---ms-sharepoint-drive.html";
  const releaseNotesURL =
    "https://xtm-cloud.atlassian.net/wiki/spaces/RN/pages/3385491665/XTM+Connect+-+Microsoft+SharePoint+Drive";

  useEffect(() => {
    axios.get("/version").then((response) => {
      setVersion(response.data);
    });
  }, []);
  return (
    <Styles.Header>
      <StyledContainer>
        <Styles.HeaderWrapper>
          <Styles.LogoWrapper to={link}>
            <img src="/xtm-logo.png" alt="XTM Logo" />
            <Styles.Headline size="big" color="white">
              {t("header.title")}{" "}
              {version && (
                <span>
                  ({t("header.version")} {version} )
                </span>
              )}
            </Styles.Headline>
          </Styles.LogoWrapper>
          <Styles.Navigation>
            <Styles.ExternalLink
              rel="noreffer"
              href={releaseNotesURL}
              target="_blank"
            >
              <ReleaseIcon />
              {t("header.menu.releaseNotes")}
            </Styles.ExternalLink>
            <Styles.ExternalLink rel="noreffer" href={helpURL} target="_blank">
              <HelpIcon />
              {t("header.menu.help")}
            </Styles.ExternalLink>
            {authState && (
              <Styles.NavigationLink to="/logout">
                <LogoutIcon />
                {t("header.menu.logOut")}
              </Styles.NavigationLink>
            )}
          </Styles.Navigation>
        </Styles.HeaderWrapper>
      </StyledContainer>
    </Styles.Header>
  );
}

export default Header;
