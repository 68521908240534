import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { CONNECTION_PROFILE_FORM_TYPES } from "../../../../constants/ConnectionProfilesFormTypes";
import { Heading } from "../../styles";

export const Header = ({ type }) => {
  const { t } = useTranslation();
  if (type === CONNECTION_PROFILE_FORM_TYPES.EDIT)
    return <Heading>{t("connectionProfiles.header.edit")}</Heading>;
  if (type === CONNECTION_PROFILE_FORM_TYPES.ADD)
    return <Heading>{t("connectionProfiles.header.add")}</Heading>;
  if (type === CONNECTION_PROFILE_FORM_TYPES.CLONE)
    return <Heading>{t("connectionProfiles.header.clone")}</Heading>;
  return null;
};

Header.propTypes = {
  type: PropTypes.oneOf(Object.values(CONNECTION_PROFILE_FORM_TYPES))
    .isRequired,
};
