import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";
import StyledSection from "components/global/Section";
import { InnerSectionContainer } from "components/global/Container";
import {
  InputLabel,
  FormRow,
  TextInput,
  CancelButton,
  SubmitButton,
  StyledCheckbox,
} from "components/global/forms";
import { fetchClients } from "data/features/clients/clientsApi";
import { WhiteSelect } from "components/Select";
import { addAdministrator } from "data/features/administrators/administratorsApi";
import { useTranslation } from "react-i18next";
import { Heading, ButtonsContainer } from "../styles";

const AddAdministrator = () => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    fetchClients({ limit: 999, isDropdown: true })
      .then((res) => setClients(res.data.objects))
      .catch((err) => toast.error(err));
  }, []);

  const onSubmit = (data) => {
    const { clientAssigned, status, email, password } = data;
    setIsDisabled(true);
    addAdministrator({
      email,
      clientAssigned,
      password,
      status: status ? "ACTIVE" : "NOT_ACTIVE",
    })
      .then((response) => {
        navigate("/administrators", { replace: true });
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        return error;
      });
  };

  useEffect(() => {
    const subscription = watch((value) => {
      const areFieldsFilled =
        value.email.length &&
        value.password.length &&
        value.confirm_password.length &&
        value.clientAssigned;
      setIsDisabled(!areFieldsFilled);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <StyledSection type="login">
      <InnerSectionContainer>
        <Heading>{t("administrators.add.label")}</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormRow bottomSpace>
            <InputLabel lead>{t("form.email.label")}*</InputLabel>
            <TextInput
              aria-label="Email"
              hasError={errors.email}
              {...register("email", {
                required: t("form.email.required"),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t("form.email.invalid"),
                },
              })}
            />
          </FormRow>
          <FormRow bottomSpace>
            <InputLabel lead>{t("form.password.label")}</InputLabel>
            <TextInput
              aria-label="Password"
              type="password"
              hasError={errors.password}
              {...register("password")}
            />
          </FormRow>
          <FormRow bottomSpace>
            <InputLabel lead>{t("form.confirmPassword.label")}</InputLabel>
            <TextInput
              aria-label="ConfirmPassword"
              type="password"
              hasError={errors.confirm_password}
              {...register("confirm_password", {
                required: t("form.confirmPassword.required"),
                validate: (value) => {
                  if (value !== watch("password")) {
                    setError("password", { type: "custom" });
                    toast.error(t("form.confirmPassword.match"));
                    return t("form.confirmPassword.match");
                  }
                  return undefined;
                },
              })}
            />
          </FormRow>
          <FormRow bottomSpace>
            <InputLabel lead>{t("form.clientAssigned.label")}*</InputLabel>
            <WhiteSelect
              aria-label="ClientAssigned"
              {...register("clientAssigned", {
                required: t("form.clientAssigned.required"),
              })}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return <em>Choose...</em>;
                }
                return selected;
              }}
              label="Choose..."
            >
              {clients?.map(({ name }) => (
                <MenuItem
                  aria-label="ClientAssignedItem"
                  key={name}
                  value={name}
                >
                  {name}
                </MenuItem>
              ))}
            </WhiteSelect>
          </FormRow>

          <FormRow>
            <InputLabel lead>{t("form.active.label")}</InputLabel>
            <div>
              <StyledCheckbox
                {...register("status")}
                defaultChecked
                color="success"
              />
            </div>
          </FormRow>
          <ButtonsContainer>
            <CancelButton
              aria-label="CancelAddAdministratorButton"
              to="/administrators"
            >
              {t("form.cancel")}
            </CancelButton>
            <SubmitButton
              aria-label="AddAdministratorButton"
              disabled={isDisabled}
              type="submit"
            >
              {t("form.save")}
            </SubmitButton>
          </ButtonsContainer>
        </form>
      </InnerSectionContainer>
    </StyledSection>
  );
};

export default AddAdministrator;
