import { styled } from "@mui/system";

export const FormRow = styled("div")`
  display: grid;
  align-items: center;
  grid-template-columns: 185px 1fr;

  ${({ bottomSpace }) =>
    bottomSpace &&
    `
        margin-bottom: 2.1rem;
    `}

  ${({ bottomSpaceLow }) =>
    bottomSpaceLow &&
    `
        margin-bottom: 1.4rem;
    `}

  ${({ contained }) =>
    contained &&
    `
        grid-template-columns: 261px 365px;
        justify-content:space-between;
    `}
    
    ${({ group }) =>
    group &&
    `
      padding-left: 3.4rem;
      padding-right: 3.4rem;
    `}

     ${({ groupInner }) =>
    groupInner &&
    `
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    `}
`;
