/* eslint-disable react/prop-types */
import React from "react";
import styled from "@emotion/styled";
import clsx from "clsx";
import { useTreeItem } from "@mui/lab/TreeItem";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";

import { Indicator, INDICATOR_TYPES } from "./Indicator";

const Container = styled("div")`
  &:after {
    z-index: ${({ isActiveBranch, isAfterSelected, isSelected }) =>
      (isActiveBranch && !isAfterSelected) || isSelected ? 10 : 1};
    display: ${({ first }) => (first ? "none" : "block")};
    content: "";
    position: absolute;
    width: ${({ isChildren }) => (isChildren ? "10px" : "42px")};
    left: -26px;
    top: -16px;
    height: calc(100%);
    border-left: 2px solid #e0e1e3;
    border-bottom: 2px solid #e0e1e3;
    border-bottom-left-radius: 2px;
    border-bottom-color: ${({ isActiveBranch, isSelected, isAfterSelected }) =>
      (isActiveBranch && !isAfterSelected) || isSelected
        ? "#38AF49"
        : "#e0e1e3"};

    border-left-color: ${({
      isSelected,
      isAfterSelected,
      isParentInActiveBranch,
    }) =>
      (isParentInActiveBranch && !isAfterSelected) || isSelected
        ? "#38AF49"
        : "#e0e1e3"};
  }
`;

export const CustomContent = ({
  parents,
  isActiveBranch,
  first,
  isChildren,
  isAfterSelected,
  isParentInActiveBranch,
}) =>
  React.forwardRef((props, ref) => {
    const {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
    } = props;

    const {
      disabled,
      expanded,
      selected,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {
      preventSelection(event);
    };

    const handleExpansionClick = (event) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (event) => {
      handleSelection({ ...event, parents });
    };

    const getIndicatorType = () => {
      if (selected) return INDICATOR_TYPES.SELECTED;
      if (isActiveBranch) return INDICATOR_TYPES.ACTIVE;
      return INDICATOR_TYPES.DESELECTED;
    };

    return (
      <Container
        first={first}
        isChildren={isChildren}
        isSelected={selected}
        isAfterSelected={isAfterSelected}
        nodeId={nodeId}
        isActiveBranch={isActiveBranch}
        isParentInActiveBranch={isParentInActiveBranch}
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.disabled]: disabled,
        })}
        onClick={handleExpansionClick}
        onMouseDown={handleMouseDown}
        ref={ref}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={handleExpansionClick} className={classes.iconContainer}>
          {icon}
        </div>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          px="8px"
          onClick={handleSelectionClick}
        >
          <Indicator type={getIndicatorType()} />
        </Box>
        <Typography component="div" className={classes.label}>
          {label}
        </Typography>
      </Container>
    );
  });
