import { Checkbox } from "@mui/material";
import { styled } from "@mui/system";

export const StyledCheckbox = styled(Checkbox)`
  width: 3rem;
  height: 3rem;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  & .MuiSvgIcon-root {
    font-size: 2.2rem;
  }
`;
