import { CONNECTION_PROFILE_STATUS, STEP_TYPES } from "../../../../constants";

const mapWorkflows = (workflows) => {
  const mappedData = {
    workflowIds: [],
    preProcessingSteps: {},
    ignoredSteps: {},
    nonCatToolSteps: {},
  };

  workflows.forEach((workflow) => {
    mappedData.workflowIds.push(workflow.xtm_workflow_id);
    workflow.steps.forEach((step) => {
      if (step.type === STEP_TYPES.IGNORED) {
        mappedData.ignoredSteps[workflow.xtm_workflow_id] = [
          ...(mappedData.ignoredSteps[workflow.xtm_workflow_id] || []),
          `${step.xtm_step_id}-${step.order_number}`,
        ];
      }

      if (step.type === STEP_TYPES.PREPROCESSING) {
        mappedData.preProcessingSteps[workflow.xtm_workflow_id] = [
          ...(mappedData.preProcessingSteps[workflow.xtm_workflow_id] || []),
          `${step.xtm_step_id}-${step.order_number}`,
        ];
      }

      if (step.type === STEP_TYPES.NON_CAT_TOOL) {
        mappedData.nonCatToolSteps[workflow.xtm_workflow_id] = [
          ...(mappedData.nonCatToolSteps[workflow.xtm_workflow_id] || []),
          `${step.xtm_step_id}-${step.order_number}`,
        ];
      }
    });
  });

  return mappedData;
};

export const fetchConnectionProfileMapper = (data) => ({
  id: data.id,
  connectionProfileName: data.connection_profile_name,
  connectionType: data.connection_type,
  active: data.connection_profile_status === CONNECTION_PROFILE_STATUS.ACTIVE,
  clientAssigned: data.client_id,
  sharePoint: {
    tokenID: null,
    loginCredentials: {
      sharePointUrl: "",
      sharePointClientID: "",
      sharePointClientSecret: "",
      sharePointTenantId: "",
      sharePointSiteName: "",
    },
    sharepointFolderPath: data.sharepoint_folder_path,
  },
  xtm: {
    tokenID: null,
    customers: data.xtm_customers_ids,
    loginCredentials: {
      xtmUrl: "",
      xtmCompanyName: "",
      xtmUser: "",
      xtmPassword: "",
    },

    hotFolders: {
      templateType: null,
      xtmTemplate: null,
      xtmProjectNamePrefix: "",
      defaultDueDate: 0,
      continuousLocalisation: true,
      detectContentSpecificTime: null,
      contentSynchroFromShInterval: "",
      projectCreatedNotify: false,
      projectFinishedNotify: false,
      progressTranslationWorkflowNotify: false,
      projectCancelledNotify: false,
      emailAddress: "",
    },
    workflows: mapWorkflows(data.workflows),
    checkProjectMinutes: data.periodicity_of_refresh,
  },
});
