import { CONNECTION_PROFILE_STATUS } from "../../../../constants";

export const fetchConnectionProfileHotFolderMapper = (data) => ({
  id: data.id,
  connectionProfileName: data.connection_profile_name,
  connectionType: data.connection_type,
  active: data.connection_profile_status === CONNECTION_PROFILE_STATUS.ACTIVE,
  clientAssigned: data.client_id,
  clientName: data.client_name,
  sharePoint: {
    tokenID: null,
    loginCredentials: {
      sharePointUrl: "",
      sharePointClientID: "",
      sharePointClientSecret: "",
      sharePointTenantId: "",
      sharePointSiteName: "",
    },
    sharepointFolderPath: data.sharepoint_source_folder_path,
    sharepointTargetPath: data.sharepoint_target_folder_path,
  },
  xtm: {
    tokenID: null,
    customer: data.xtm_customers_ids[0] || null,
    customers: [],
    workflows: {
      workflowIds: [],
      preProcessingSteps: {},
      ignoredSteps: {},
    },
    checkProjectMinutes: "",
    loginCredentials: {
      xtmUrl: "",
      xtmCompanyName: "",
      xtmUser: "",
      xtmPassword: "",
    },
    hotFolders: {
      templateType: data.template_type,
      xtmTemplate: data.template_id,
      xtmProjectNamePrefix: data.xtm_project_name_prefix,
      defaultDueDate: data.default_due_date_ahead_in_days,
      detectContentSpecificTime: data.detect_changes_and_submit_at
        .map((item) => item.toString().padStart(2, "0"))
        .join(":"),
      contentSynchroFromShInterval: data.content_synchro_from_sh_interval,
      projectCreatedNotify: data.notify_project_created,
      projectFinishedNotify:
        data.notify_job_or_project_finished_and_imported_back_to_sp,
      projectCancelledNotify: data.notify_project_cancelled_or_archived,
      progressTranslationWorkflowNotify:
        data.notify_translation_workflow_progress,
      continuousLocalisation: data.automatic_continuous_localisation,
      emailAddress: data.email_address,
    },
  },
});
