import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { InputLabel, TextInput, FormError } from "components/global/forms";
import { fetchForgotPassword } from "data/features/auth/authApi";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import * as Styles from "../styles";

function ForgotPasswordForm() {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsDisabled(true);
    fetchForgotPassword(data)
      .then((response) => {
        toast.success("Reset password link sent! Check your inbox.");
        return response;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setError("email", {
            type: "custom",
            message: "We don’t recognise this email. Try again.",
          });
        }
        return error;
      });
  };

  useEffect(() => {
    const subscription = watch((value) => {
      const isEmailFilled = value.email.length;
      setIsDisabled(!isEmailFilled);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormError>
        <span>{errors.email?.message}</span>
      </FormError>
      <InputLabel>{t("form.email.label")}</InputLabel>
      <TextInput
        aria-label="Email"
        type="email"
        hasError={errors.email}
        {...register("email", {
          required: t("form.email.required"),
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: t("form.email.invalid"),
          },
        })}
      />
      <Styles.LoginSubmitButton
        aria-label="ResetPasswordButton"
        disabled={isDisabled}
      >
        {t("resetPassword.form.submit.label")}
      </Styles.LoginSubmitButton>
      <Styles.LoginCancelButton
        aria-label="CancelResetPasswordButton"
        to="/login"
      >
        {t("form.cancel")}
      </Styles.LoginCancelButton>
    </form>
  );
}

export default ForgotPasswordForm;
