import {
  ConnectionProfilesActionCell,
  ConnectionTypeCell,
  DateCell,
  StatusCell,
} from "components/TableComponent/cells";
import i18n from "config/i18n";

const { t } = i18n;

export const connectionProfileColumns = [
  {
    Header: t("column.id"),
    accessor: "id",
    width: "46px",
  },
  {
    Header: t("column.connectionProfiles.systemName"),
    accessor: "integrated_system_name",
    width: "190px",
  },
  {
    Header: t("column.connectionProfiles.connectionType"),
    accessor: "connection_type",
    Cell: ConnectionTypeCell,
    width: "140px",
  },
  {
    Header: t("column.connectionProfiles.connectionProfileName"),
    accessor: "connection_profile_name",
    width: "190px",
  },
  {
    Header: t("column.connectionProfiles.clientName"),
    accessor: "client_name",
    width: "190px",
  },
  {
    Header: t("column.status"),
    accessor: "connection_profile_status",
    Cell: StatusCell,
    width: "80px",
  },
  {
    Header: t("column.dateAdded"),
    accessor: "created_date",
    Cell: DateCell,
    width: "150px",
  },
  {
    Header: t("column.dateModified"),
    accessor: "modification_date",
    Cell: DateCell,
    width: "145px",
  },
  {
    Header: "",
    accessor: "edit",
    width: "50px",
    disableSortBy: false,
    Cell: ConnectionProfilesActionCell,
  },
];
