import React, { useMemo, useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSVDownload } from "react-csv";
import { TableComponent, Tooltip } from "components";
import {
  fetchEventLogsAsync,
  selectEventLogsData,
  selectEventLogsError,
  selectFilterDate,
  selectFilterProfiles,
  selectFilterTypes,
  selectModal,
  selectRowsToExport,
} from "data";
import { HeadingActionButton } from "components/TableComponent/components/HeadingActionButton";
import { ReactComponent as NotificationIcon } from "assets/notification-icon.svg";
import { ReactComponent as ExportIcon } from "assets/export-icon.svg";
import { exportEventLogsToCsv } from "data/features/eventLogs/eventLogApi";
import { EventLogsModal } from "pages/Panel/EventLog/EventLogsModal";
import { FilterBox } from "components/FilterBox";
import Container from "components/global/Container";
import {
  selectEventLogsSort,
  updateSort,
} from "data/features/eventLogs/eventLogsSlice";
import { useTranslation } from "react-i18next";
import { camelCase } from "lodash";
import { eventLogsColumns } from "../helpers";

export function EventLog() {
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  const [dataToDownload, setDataToDownload] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector(selectEventLogsData);
  const rowsToExport = useSelector(selectRowsToExport);
  const filterTypes = useSelector(selectFilterTypes);
  const filterProfiles = useSelector(selectFilterProfiles);
  const filterDate = useSelector(selectFilterDate);
  const error = useSelector(selectEventLogsError);
  const modal = useSelector(selectModal);
  const selectSort = useSelector(selectEventLogsSort);
  const { t } = useTranslation();

  const columns = useMemo(() => eventLogsColumns, []);

  const getFiltersLength = useCallback(
    () =>
      filterTypes.length +
      filterProfiles.length +
      (filterDate.selection.endDate ? 1 : 0),
    [filterTypes, filterProfiles, filterDate]
  );

  const handleUpdateSort = (sort) => {
    dispatch(updateSort(sort));
  };

  const handleFetchEventLogs = useCallback(
    (params) => dispatch(fetchEventLogsAsync(params)),
    []
  );

  const handleExportEventLogs = () => {
    if (!rowsToExport.length) return;
    exportEventLogsToCsv(rowsToExport).then((response) => {
      const preformatedData = response.data
        .split("\n")
        .map((item) => item.split(","))
        .map((item) => item.map((itemColumn) => itemColumn.slice(1, -1)));
      setDataToDownload(preformatedData);
    });
  };

  const handleToggleFilterBox = () => {
    setFilterBoxOpen((prevState) => !prevState);
  };

  useEffect(() => {
    handleFetchEventLogs({
      sort: { field: camelCase(selectSort.field), order: selectSort.order },
    });
  }, [dispatch]);

  useEffect(() => {
    if (dataToDownload) {
      setDataToDownload(null);
    }
  }, [dataToDownload]);

  return (
    <>
      {error && JSON.stringify(error)}
      {data && (
        <Container small>
          <TableComponent
            fetchData={handleFetchEventLogs}
            columns={columns}
            data={data.objects}
            totalItems={data.total_elements}
            searchQuery={(value = "") => `{ "message":"${value}" }`}
            sortObject={selectSort}
            updateSortObject={handleUpdateSort}
            renderHeading={({ setGlobalFilter }) => (
              <>
                {filterBoxOpen && (
                  <FilterBox
                    setGlobalFilter={setGlobalFilter}
                    toggleFilterBox={handleToggleFilterBox}
                  />
                )}

                <HeadingActionButton
                  aria-label="ToggleFilterboxButton"
                  onClick={handleToggleFilterBox}
                  disableRipple
                  active={getFiltersLength()}
                >
                  <NotificationIcon />
                  {t("eventLogs.filter.button")}

                  {getFiltersLength() > 0 && (
                    <span data-testid="ToggleFilterboxButtonCount">
                      ({getFiltersLength()})
                    </span>
                  )}
                </HeadingActionButton>
                <Tooltip
                  title={t("eventLogs.export.tooltip")}
                  arrow
                  placement="bottom"
                  hidden={rowsToExport.length}
                >
                  <HeadingActionButton
                    disableRipple
                    onClick={handleExportEventLogs}
                    customDisabled={!rowsToExport.length}
                  >
                    <ExportIcon />
                    {t("eventLogs.export.button")}
                  </HeadingActionButton>
                </Tooltip>
              </>
            )}
          />
        </Container>
      )}
      <EventLogsModal message={modal.data} />
      {dataToDownload && <CSVDownload data={dataToDownload} />}
    </>
  );
}
