import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as SelectedIcon } from "assets/selected.svg";
import { ReactComponent as DeselectedIcon } from "assets/deselected.svg";
import { ReactComponent as ActiveIcon } from "assets/active.svg";

export const INDICATOR_TYPES = {
  SELECTED: "SELECTED",
  DESELECTED: "DESELECTED",
  ACTIVE: "ACTIVE",
};

export const Indicator = ({ type }) => {
  if (type === INDICATOR_TYPES.SELECTED)
    return <SelectedIcon aria-label="selected-icon" />;
  if (type === INDICATOR_TYPES.DESELECTED)
    return <DeselectedIcon aria-label="deselected-icon" />;
  if (type === INDICATOR_TYPES.ACTIVE)
    return <ActiveIcon aria-label="active-icon" />;
  return null;
};

Indicator.propTypes = {
  type: PropTypes.oneOf(Object.values(INDICATOR_TYPES)).isRequired,
};
