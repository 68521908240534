import React from "react";
import { Button, styled } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const AddButton = styled(({ children, ...props }) => (
  <Button {...props} startIcon={<AddCircleIcon />}>
    {children}
  </Button>
))`
  color: #545454;
  font-size: 14px;
  text-transform: none;
  padding: 8px 6px;
  line-height: 1;

  svg {
    font-size: 16px !important;
    margin-right: 4px !important;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
