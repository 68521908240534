import React, { useEffect } from "react";
import { FormRow, InputLabel } from "components/global/forms";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { MenuItem, WhiteSelect } from "components/Select";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { handleUpdateState } from "../helpers";
import {
  fetchCustomersAsync,
  selectModifyCustomers,
  selectModifyForm,
  selectXtmTokenId,
} from "../../../../data/features/connectionProfiles/connectionProfileSlice";

const getCustomerLabel = (customers, value) =>
  customers.data?.find((customer) => customer.id === value)?.name;

const BoxComponent = ({ selected, mouseDown, onDelete, customers }) => (
  <Box
    sx={{
      display: "flex",
      zIndex: 1400,
      flexWrap: "wrap",
      gap: 0.5,
    }}
  >
    {Array.isArray(selected) ? (
      selected.map((value) => (
        <Chip
          sx={{
            fontSize: "1.2rem",
            height: "auto",
            padding: "3px 0",
            textTransform: "capitalize",
          }}
          onMouseDown={mouseDown}
          onDelete={onDelete(value)}
          deleteIcon={<DeleteIcon />}
          key={value}
          label={getCustomerLabel(customers, value)}
        />
      ))
    ) : (
      <Chip
        sx={{
          fontSize: "1.2rem",
          height: "auto",
          padding: "3px 0",
          textTransform: "capitalize",
        }}
        onMouseDown={mouseDown}
        onDelete={onDelete}
        deleteIcon={<DeleteIcon />}
        key={selected}
        label={getCustomerLabel(customers, selected)}
      />
    )}
  </Box>
);

BoxComponent.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  mouseDown: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
};

export const XTMCustomers = ({ selectIndividual }) => {
  const dispatch = useDispatch();
  const tokenID = useSelector(selectXtmTokenId);
  const customers = useSelector(selectModifyCustomers);
  const form = useSelector(selectModifyForm);
  const { t } = useTranslation();
  const params = useLocation();
  const profileID = Number(new URLSearchParams(params.search).get("id"));

  useEffect(() => {
    if (profileID) {
      dispatch(
        fetchCustomersAsync({
          id: profileID,
          isProfileId: true,
        })
      );
    }
  }, [profileID]);

  useEffect(() => {
    if (tokenID) {
      handleUpdateState([], "xtm.customers");
      handleUpdateState(null, "xtm.customer");
      dispatch(
        fetchCustomersAsync({
          id: tokenID,
          isProfileId: false,
        })
      );
    }
  }, [tokenID]);

  const handleChange = (ev) =>
    handleUpdateState(ev.target.value, "xtm.customers");

  const handleChangeIndividual = (ev) =>
    handleUpdateState(ev.target.value, "xtm.customer");

  const handleMouseDown = (event) => {
    event.stopPropagation();
  };

  const handleDelete = (value) => () => {
    handleUpdateState(
      form.xtm.customers.filter((customer) => customer !== value),
      "xtm.customers"
    );
  };

  const handleDeleteIndividual = () => {
    handleUpdateState(null, "xtm.customer");
  };

  const renderSelect = () => {
    if (selectIndividual) {
      return (
        <WhiteSelect
          onChange={handleChangeIndividual}
          value={form.xtm.customer}
          renderValue={(selected) => (
            <BoxComponent
              selected={selected}
              mouseDown={handleMouseDown}
              onDelete={handleDeleteIndividual}
              customers={customers}
            />
          )}
        >
          {customers.data
            ? customers.data.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))
            : null}
        </WhiteSelect>
      );
    }
    return (
      <WhiteSelect
        multiple
        onChange={handleChange}
        value={form.xtm.customers}
        renderValue={(selected) => (
          <BoxComponent
            selected={selected}
            mouseDown={handleMouseDown}
            onDelete={handleDelete}
            customers={customers}
          />
        )}
      >
        {customers?.data
          ? customers.data.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))
          : null}
      </WhiteSelect>
    );
  };

  return (
    <FormRow contained bottomSpace group>
      <InputLabel lead>
        {t("connectionProfiles.form.xtm.customers.label")}*
      </InputLabel>
      {renderSelect()}
    </FormRow>
  );
};

XTMCustomers.propTypes = {
  selectIndividual: PropTypes.bool,
};

XTMCustomers.defaultProps = {
  selectIndividual: false,
};
