import React, { forwardRef } from "react";
import {
  Select as MUISelect,
  MenuItem as MUIMenuItem,
  selectClasses,
  chipClasses,
} from "@mui/material";
import styled from "@emotion/styled";

export const Select = styled((props) => (
  <MUISelect
    {...props}
    MenuProps={{
      PaperProps: {
        sx: {
          borderRadius: 0,
          border: "1px solid #dddddd",
          margin: 0,
          boxShadow: "none",
          transform: "translate(-0.5px, -2px)  !important",
          ul: {
            padding: 0,
          },
          li: {
            paddingInline: "15.5px",
          },
        },
      },
    }}
  />
))`
  .${selectClasses.select} {
    padding: 4.5px 8px;
    border: 1px solid #dddd;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  fieldset {
    display: none;
  }

  .${selectClasses.icon} {
    transform: translateX(-3px);
  }

  &:before {
    border-bottom: none !important;
  }

  &:after {
    border-bottom: none !important;
  }
`;

export const WhiteSelect = styled(
  // eslint-disable-next-line react/prop-types
  forwardRef(({ selectProps, ...props }, ref) => (
    <MUISelect
      ref={ref}
      {...props}
      SelectDisplayProps={selectProps}
      MenuProps={{
        PaperProps: {
          sx: {
            borderRadius: 0,
            border: "1px solid #dddddd",
            margin: 0,
            boxShadow: "none",
            transform: "translate(-0.5px, -2px)  !important",
            ul: {
              padding: 0,
              maxHeight: "140px",
              overflow: "auto",
            },
            li: {
              paddingInline: "15.5px",
              paddingTop: 0,
              paddingBottom: 0,
              minHeight: "28px",
              fontSize: "1.3rem",
              lineHeight: "1.6rem",
            },
          },
        },
      }}
    />
  ))
)`
  .${selectClasses.select} {
    padding: 0 8px;
    border: 1px solid #dddd;
    font-size: 1.3rem;
    line-height: 1.6rem;
    background-color: #fff;
    border-radius: 0 !important;
    min-height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .${selectClasses.icon} {
    transform: translateX(-3px);
  }

  .${chipClasses.root} {
    margin: 1px 0;
  }

  fieldset {
    border: none;
  }

  &:before {
    border-bottom: none !important;
  }

  &:after {
    border-bottom: none !important;
  }
`;

export const MenuItem = styled(MUIMenuItem)`
  &.selected {
    background-color: transparent !important;
  }
`;

export const SelectOptionText = styled("p")`
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-left: 1rem;
`;
