import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  clientsReducer,
  authReducer,
  administratorsReducer,
  eventLogsReducer,
  connectionProfilesReducer,
} from ".";

const reducers = combineReducers({
  clients: clientsReducer,
  administrators: administratorsReducer,
  eventLogs: eventLogsReducer,
  auth: authReducer,
  connectionProfiles: connectionProfilesReducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: reducers,
    preloadedState,
  });
