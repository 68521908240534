import axios from "axios";

export const fetchClients = ({
  filter = "",
  page = 0,
  limit = 10,
  sort = { field: "name", order: "ASC" },
  isDropdown = false,
} = {}) =>
  axios.get("/clients", {
    params: {
      filter,
      page: String(page),
      limit: String(limit),
      sort: JSON.stringify(sort),
      isDropdown,
    },
    headers: {
      "Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

export const addClient = ({
  name = "",
  status = "ACTIVE",
  showWorkingCopy = false,
} = {}) =>
  axios.post("/clients", {
    name,
    status,
    showWorkingCopy,
  });

export const editClient = ({
  id,
  name = "",
  status = "ACTIVE",
  showWorkingCopy = false,
} = {}) =>
  axios.put("/clients", {
    id,
    name,
    status,
    showWorkingCopy,
  });

export const fetchClientById = (id) =>
  axios.get(`/clients/${id}`, {
    headers: {
      "Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

export const isConnectionTypeDropdownEnabled = () =>
  axios.get("/clients/is-enabled");
