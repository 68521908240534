import React from "react";
import StyledSection from "components/global/Section";
import { useTranslation } from "react-i18next";
import * as Styles from "./styles";
import ForgotPasswordForm from "./components/ForgotPasswordForm";

function ForgotPassword() {
  const { t } = useTranslation();
  return (
    <StyledSection type="login" spaceTop>
      <Styles.LoginBox>
        <Styles.LoginBoxHeader aria-label="Forgot Password Header">
          {t("resetPassword.box.header")}
        </Styles.LoginBoxHeader>
        <Styles.LoginForgotPasswordText>
          {t("resetPassword.box.text")}
        </Styles.LoginForgotPasswordText>
        <Styles.LoginBoxContent>
          <ForgotPasswordForm />
        </Styles.LoginBoxContent>
      </Styles.LoginBox>
    </StyledSection>
  );
}

export default ForgotPassword;
