import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import { StyledCheckbox } from "components/global/forms";
import { MenuItem, SelectOptionText, WhiteSelect } from "components/Select";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { Box } from "@mui/system";
import { CalendarInput } from "components/CalendarInput/CalendarInput";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFilterDate,
  selectFilterProfiles,
  selectFilterTypes,
  setFilterDate,
  setFilterProfiles,
  setFilterTypes,
} from "data";
import { Tooltip } from "components/Tooltip";
import { useTranslation } from "react-i18next";
import { fetchConnectionProfileNames } from "data/features/connectionProfiles/connectionProfileApi";
import {
  ClearFiltersButton,
  FilterBoxButtons,
  FilterBoxColumn,
  FilterBoxContainer,
  FilterBoxFilters,
  FilterLabel,
  FilterSubmitButton,
} from "./styles";
import { formatEndDate, formatStartDate } from "./helpers";

export const FilterBox = ({ toggleFilterBox, setGlobalFilter }) => {
  const [profiles, setProfiles] = useState([]);
  const { t } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dispatch = useDispatch();
  const selectTypes = useSelector(selectFilterTypes);
  const selectProfiles = useSelector(selectFilterProfiles);
  const selectDate = useSelector(selectFilterDate);

  const handleApplyFilters = () => {
    if (isButtonDisabled) return;

    const formatedStartDate = formatStartDate(selectDate.selection.startDate);
    const formatedEndDate = formatEndDate(selectDate.selection.endDate);

    const selectedTypes = selectTypes.map((item) => `"${item.toUpperCase()}"`);
    const selectedProfiles = selectProfiles.map((item) => `"${item}"`);
    const selectedDate = `"earlier_than_event_date": "${formatedEndDate}","later_than_event_date": "${formatedStartDate}"`;

    const filters = [];

    if (selectedTypes.length) {
      filters.push(`"type": [${selectedTypes}]`);
    }

    if (selectedProfiles.length) {
      filters.push(`"connection_profile_name": [${selectedProfiles}]`);
    }

    if (selectDate.selection.endDate) {
      filters.push(selectedDate);
    }

    const query = `{${filters.filter((element) => element !== "").join(",")}}`;

    setGlobalFilter(query);

    toggleFilterBox();
  };

  const handleTypesChange = (event) => {
    dispatch(setFilterTypes(event.target.value));
  };

  const handleSelectProfiles = (event) => {
    dispatch(setFilterProfiles(event.target.value));
  };

  const handleRemoveSelectedProfile = (profile) => {
    dispatch(setFilterProfiles(selectProfiles.filter((p) => p !== profile)));
  };

  const handleRemoveSelectedType = (value) => {
    dispatch(setFilterTypes(selectTypes.filter((type) => type !== value)));
  };

  const handleClearFilters = () => {
    dispatch(setFilterProfiles([]));
    dispatch(setFilterTypes([]));
    dispatch(
      setFilterDate({
        selection: {
          startDate: new Date(),
          endDate: null,
          key: "selection",
        },
      })
    );

    setGlobalFilter("");
  };

  const types = ["Success", "Error", "Warning", "Information"];

  useEffect(() => {
    if (
      selectTypes.length > 0 ||
      selectProfiles.length > 0 ||
      selectDate.selection.endDate
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [selectTypes, selectProfiles, selectDate]);

  useEffect(() => {
    fetchConnectionProfileNames().then((response) => {
      setProfiles(response.data);
    });
  }, []);

  return (
    <FilterBoxContainer aria-label="FilterBox">
      <FilterBoxFilters>
        <FilterBoxColumn>
          <FilterLabel>{t("filterBox.type")}</FilterLabel>
          <WhiteSelect
            value={selectTypes}
            onChange={handleTypesChange}
            multiple
            aria-label="FilterType"
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  zIndex: 1,
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected.map((value) => (
                  <Chip
                    sx={{
                      fontSize: "1.3rem",
                      height: "auto",
                      padding: "3px 0",
                      textTransform: "capitalize",
                    }}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onDelete={() => handleRemoveSelectedType(value)}
                    deleteIcon={<DeleteIcon />}
                    key={value}
                    label={value}
                  />
                ))}
              </Box>
            )}
          >
            {types.map((type) => (
              <MenuItem
                value={type}
                key={type}
                classes={{ selected: "selected" }}
              >
                <StyledCheckbox
                  checked={selectTypes.includes(type)}
                  color="success"
                />
                <SelectOptionText aria-label={type}>{type}</SelectOptionText>
              </MenuItem>
            ))}
          </WhiteSelect>
        </FilterBoxColumn>

        <FilterBoxColumn>
          <FilterLabel>{t("filterBox.connectionProfile")}</FilterLabel>
          <WhiteSelect
            value={selectProfiles}
            onChange={handleSelectProfiles}
            multiple
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  zIndex: 1,
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected.map((value) => (
                  <Chip
                    sx={{
                      fontSize: "1.3rem",
                      height: "auto",
                      padding: "3px 0",
                      textTransform: "capitalize",
                    }}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onDelete={() => handleRemoveSelectedProfile(value)}
                    deleteIcon={<DeleteIcon />}
                    key={value}
                    label={value}
                  />
                ))}
              </Box>
            )}
          >
            {profiles?.map((profile) => (
              <MenuItem
                key={profile}
                value={profile}
                classes={{ selected: "selected" }}
              >
                <StyledCheckbox
                  checked={selectProfiles.includes(profile)}
                  color="success"
                />
                <SelectOptionText>{profile}</SelectOptionText>
              </MenuItem>
            ))}
          </WhiteSelect>
        </FilterBoxColumn>

        <FilterLabel>{t("filterBox.dateOccured")}</FilterLabel>
        <CalendarInput />
      </FilterBoxFilters>
      <FilterBoxButtons>
        <ClearFiltersButton onClick={handleClearFilters}>
          {t("filterBox.clearFilters")}
        </ClearFiltersButton>
        <Tooltip
          title={t("filterBox.criterionTooltip")}
          arrow
          placement="bottom"
          hidden={!isButtonDisabled}
        >
          <FilterSubmitButton
            aria-label="ApplyFilterButton"
            onClick={handleApplyFilters}
            customDisabled={isButtonDisabled}
          >
            {t("filterBox.applyFilters")}
          </FilterSubmitButton>
        </Tooltip>
      </FilterBoxButtons>
    </FilterBoxContainer>
  );
};

FilterBox.propTypes = {
  toggleFilterBox: PropTypes.func.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
};
