import React, { useCallback, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdministratorsAsync,
  selectAdministratorsData,
  selectAdministratorsError,
} from "data";
import { TableComponent } from "components";
import { AddButton } from "components/TableComponent/components";
import Container from "components/global/Container";
import {
  selectAdministratorsSort,
  updateSort,
} from "data/features/administrators/administratorsSlice";
import { useTranslation } from "react-i18next";
import { administratorColumns } from "../helpers";

const AdministratorsHeading = ({ handleAdd }) => {
  const { t } = useTranslation();
  return (
    <AddButton onClick={() => handleAdd("/administrators/add")}>
      {t("administrators.add.label")}
    </AddButton>
  );
};
AdministratorsHeading.propTypes = {
  handleAdd: PropTypes.func.isRequired,
};

function Administrators() {
  const dispatch = useDispatch();
  const selectSort = useSelector(selectAdministratorsSort);
  const data = useSelector(selectAdministratorsData);
  const error = useSelector(selectAdministratorsError);

  const handleFetch = useCallback(
    (params) => dispatch(fetchAdministratorsAsync(params)),
    []
  );

  const handleUpdateSort = (sort) => {
    dispatch(updateSort(sort));
  };

  const columns = useMemo(() => administratorColumns, []);

  useEffect(() => {
    handleFetch({ sort: selectSort });
  }, [dispatch]);

  return (
    <>
      {error && JSON.stringify(error)}
      {data && (
        <Container small>
          <TableComponent
            fetchData={handleFetch}
            columns={columns}
            data={data.objects}
            totalItems={data.total_elements}
            renderHeading={AdministratorsHeading}
            sortObject={selectSort}
            updateSortObject={handleUpdateSort}
          />
        </Container>
      )}
    </>
  );
}

export default Administrators;
