import React from "react";
import PropTypes from "prop-types";

import StyledSection from "components/global/Section";
import { useRouteMatch } from "helpers/useRouteMatch";
import TabsBar from "./components/TabsBar";

const MemoizedTabsBar = React.memo(TabsBar);

function Panel({ tab }) {
  const routeMatch = useRouteMatch([
    "/clients",
    "/administrators",
    "/connection-profiles",
    "/event-log",
  ]);

  const currentTab = routeMatch?.pattern?.path ?? "/clients";

  return (
    <div>
      <MemoizedTabsBar value={currentTab} />
      <StyledSection type="panel">{tab}</StyledSection>
    </div>
  );
}

Panel.propTypes = {
  tab: PropTypes.node,
};

Panel.defaultProps = {
  tab: null,
};

export default Panel;
