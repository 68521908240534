import { styled } from "@mui/system";

export const TextInput = styled("input")`
  width: 100%;
  min-height: 3.2rem;
  border: none;
  outline: none;
  padding: 0.7rem 0.8rem;
  font-size: 1.3rem;
  line-height: 1.523rem;
  color: ${({ theme }) => theme.palette.text.main};
  border: 1px solid ${({ theme }) => theme.palette.border.main};
  background-color: ${({ theme }) => theme.palette.white.main};

  ${({ hasError, theme }) =>
    hasError &&
    `
		border: 1px solid ${theme.palette.error.main};
	`}
`;
