import axios from "axios";
import { fetchRefreshToken } from "data/features/auth/authApi";
import { store } from "data/store";
import { EnvService, LocalStorageService, RefreshTokenService } from "services";

const URLS_TO_SKIP = [
  "auth/login",
  "auth/forgot-password",
  "auth/change-password",
  "/version",
];

export const setAuthorizationToken = () => {
  const token = LocalStorageService.getAuthToken();
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

const redirectToLogin = () => {
  window.location.href = "/login";
  LocalStorageService.setAuthToken("");
  store.dispatch({
    type: "auth/logout",
  });
};

export const configureAxios = () => {
  axios.defaults.baseURL = EnvService.getApiUrl();
  axios.interceptors.request.use(
    (config) => {
      if (!URLS_TO_SKIP.includes(config.url)) {
        if (RefreshTokenService.hasTokenExpired()) {
          redirectToLogin();
        }

        if (RefreshTokenService.canRefreshToken()) {
          fetchRefreshToken(LocalStorageService.getRefreshToken()).then(
            (response) => {
              LocalStorageService.setAuthToken(response.data.token);
              LocalStorageService.setRefreshToken(response.data.refresh_token);
              setAuthorizationToken();
            }
          );
        }
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response.status === 401 &&
        error.response?.data?.message === "Unauthorized"
      ) {
        redirectToLogin();
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
  setAuthorizationToken();
};
