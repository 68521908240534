import React from "react";
import { ReactComponent as SuccessIcon } from "assets/success-icon.svg";
import { ReactComponent as ErrorIcon } from "assets/error-icon.svg";
import { ReactComponent as InfoIcon } from "assets/info-icon.svg";
import { Toaster as HotToaster, ToastBar } from "react-hot-toast";

const Toaster = () => (
  <HotToaster
    containerStyle={{
      top: 76,
    }}
    toastOptions={{
      style: {
        fontSize: "1.3rem",
        lineHeight: "1.5rem",
        color: "#333333",
        borderRadius: "0.2rem",
        fontFamily: "roboto, sans-serif",
        paddingTop: "1.2rem",
        maxWidth: "360px",
        width: "100%",
        paddingBottom: "1.2rem",
        borderLeft: "4px solid #00a4e4",
        boxShadow:
          "0px -1px 5px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.1);",
      },
      success: {
        style: {
          borderLeft: "4px solid #38af49",
        },
      },
      error: {
        style: {
          borderLeft: "4px solid #FF6347",
        },
      },
      position: "top-right",
    }}
  >
    {(t) => (
      <ToastBar toast={t}>
        {({ message }) => (
          <>
            {t.type === "blank" && <InfoIcon />}
            {t.type === "success" && <SuccessIcon />}
            {t.type === "error" && <ErrorIcon />}
            <div>{message}</div>
          </>
        )}
      </ToastBar>
    )}
  </HotToaster>
);

export default Toaster;
