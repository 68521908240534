import { styled } from "@mui/system";

export const Wrapper = styled("div")`
  padding: 3rem 0;
  width: 100%;
  border: 1px solid #6f8294;
  position: relative;

  ${({ inner }) =>
    inner &&
    `
		margin: 0 1.7rem;
		width: calc(100% - 3.4rem);
	`}

  ${({ spaceTop }) =>
    spaceTop &&
    `
		margin-top: ${spaceTop};
	`}
`;

export const Name = styled("p")`
  position: absolute;
  top: 0;
  left: 0.8rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  padding: 0 0.8rem;
  font-weight: 400;
  background-color: ${({ theme }) => theme.palette.background.light};
  transform: translateY(-50%);
  color: ${({ theme }) => theme.palette.primary.main};

  ${({ inner, theme }) =>
    inner &&
    `
    text-transform: none;
    font-weight: 700;
    color:  ${theme.palette.text.main};

  `}
`;
