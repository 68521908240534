/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import { TreeItem, treeItemClasses } from "@mui/lab";
import styled from "@emotion/styled";

import { CustomContent } from "./CustomContent";

const CustomizedItem = (props) => (
  <TreeItem
    {...props}
    ContentComponent={CustomContent({
      parents: props.parents,
      isActiveBranch: props.isActiveBranch,
      first: props.first,
      isChildren: props.isChildren,
      isAfterSelected: props.isAfterSelected,
      isParentInActiveBranch: props.isParentInActiveBranch,
    })}
    TransitionComponent={null}
  />
);

export const Item = styled(CustomizedItem)`
  & {
    position: relative;
    opacity: ${({ isDisabled }) => (isDisabled ? "50%" : "100%")};
  }

  &:not(:last-of-type):before {
    display: ${({ first }) => first && "none"};
    content: "";
    position: absolute;
    width: 16px;
    top: 14px;
    z-index: 5;
    left: -26px;
    height: calc(100% - 30px);
    border-left: 2px solid #e0e1e3;
    border-left-color: ${({
      isParentInActiveBranch,
      isAfterSelected,
      isActiveBranch,
    }) =>
      isParentInActiveBranch && !isAfterSelected && !isActiveBranch
        ? "#38AF49"
        : "#e0e1e3"};
  }

  & .${treeItemClasses.content} {
    padding: 6px 0 !important;
    display: flex;
    align-items: center;
    position: relative;
  }

  & .${treeItemClasses.content} {
    &:hover {
      background: none;
    }
  }

  & .${treeItemClasses.label} {
    font-size: 12px !important;
  }

  & .${treeItemClasses.iconContainer} {
    height: 16px !important;
    width: 16px !important;
    svg {
      height: 16px !important;
      width: 16px !important;
    }
  }

  & .${treeItemClasses.group} {
    position: relative;
    padding-left: 16px;
    padding-top: 10px;
  }
`;

CustomizedItem.propTypes = {
  parents: PropTypes.array.isRequired,
  isActiveBranch: PropTypes.bool.isRequired,
  first: PropTypes.bool.isRequired,
  isChildren: PropTypes.bool.isRequired,
  isAfterSelected: PropTypes.bool.isRequired,
  isParentInActiveBranch: PropTypes.bool.isRequired,
};
