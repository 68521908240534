import React from "react";
import {
  FormRow,
  StyledCheckbox,
  TextInput,
  InputLabel,
} from "components/global/forms";
import { MenuItem, WhiteSelect } from "components/Select";
import InfoIcon from "@mui/icons-material/Info";
import { FormFlexRow } from "components/global/forms/FormFlexRow";
import { BorderedSection } from "components/BorderedSection";
import { useSelector } from "react-redux";
import { selectModifyForm } from "data";
import { Tooltip } from "components";
import { useTranslation } from "react-i18next";
import { handleUpdateCheckbox, handleUpdateState } from "../helpers";
import { XTMTemplate } from "./XTMTemplate";

export const XTMHotFolders = () => {
  const form = useSelector(selectModifyForm);
  const { t } = useTranslation();

  const handleChangeTemplateType = (ev) => {
    handleUpdateState(ev.target.value, "xtm.hotFolders.templateType");
  };

  return (
    <>
      <FormRow contained group bottomSpaceLow>
        <InputLabel lead>
          {t("connectionProfiles.form.xtm.templateType.label")}
        </InputLabel>
        <WhiteSelect
          value={form.xtm.hotFolders.templateType}
          onChange={handleChangeTemplateType}
        >
          <MenuItem value="ALL">ALL</MenuItem>
          <MenuItem value="GLOBAL">GLOBAL</MenuItem>
          <MenuItem value="CUSTOMERS">CUSTOMERS</MenuItem>
        </WhiteSelect>
      </FormRow>
      {form.xtm.hotFolders.templateType && form.xtm.customer && <XTMTemplate />}
      <FormRow contained group bottomSpaceLow>
        <InputLabel lead sx={{ display: "flex", alignItems: "center" }}>
          {t("connectionProfiles.form.xtm.projectNamePrefix.label")}*
          <Tooltip
            title={t("connectionProfiles.form.xtm.projectNamePrefix.tooltip")}
            arrow
            placement="top"
            sx={{ maxWidth: "255px" }}
          >
            <InfoIcon
              sx={{
                color: "#00A4E4",
                marginLeft: "0.3rem",
                fontSize: "1.8rem",
              }}
            />
          </Tooltip>
        </InputLabel>
        <TextInput
          onChange={(ev) =>
            handleUpdateState(
              ev.target.value,
              "xtm.hotFolders.xtmProjectNamePrefix"
            )
          }
          value={form.xtm.hotFolders.xtmProjectNamePrefix}
          sx={{ width: "160px" }}
        />
      </FormRow>
      <FormRow contained group bottomSpaceLow>
        <InputLabel lead sx={{ display: "flex", alignItems: "center" }}>
          {t("connectionProfiles.form.xtm.defaultDueDate.label")}*
          <Tooltip
            title={t("connectionProfiles.form.xtm.defaultDueDate.tooltip")}
            arrow
            placement="top"
          >
            <InfoIcon
              sx={{
                color: "#00A4E4",
                marginLeft: "0.3rem",
                fontSize: "1.8rem",
              }}
            />
          </Tooltip>
        </InputLabel>
        <TextInput
          onChange={(ev) =>
            handleUpdateState(ev.target.value, "xtm.hotFolders.defaultDueDate")
          }
          value={form.xtm.hotFolders.defaultDueDate}
          sx={{ width: "160px" }}
        />
      </FormRow>
      <BorderedSection
        spaceTop="4rem"
        inner
        name={t(
          "connectionProfiles.form.xtm.continuousLocalisation.sectionName"
        )}
      >
        <FormRow contained bottomSpaceLow groupInner>
          <InputLabel lead>
            {t(
              "connectionProfiles.form.xtm.contentSynchroFromShInterval.label"
            )}
            *
          </InputLabel>
          <TextInput
            sx={{ width: "177px" }}
            type="text"
            onChange={(ev) => {
              handleUpdateState(
                ev.target.value,
                "xtm.hotFolders.contentSynchroFromShInterval"
              );
            }}
            value={form.xtm.hotFolders.contentSynchroFromShInterval}
          />
        </FormRow>
        <FormRow contained bottomSpaceLow groupInner>
          <InputLabel lead>
            {t("connectionProfiles.form.xtm.detectContentSpecificTime.label")}*
          </InputLabel>
          <TextInput
            sx={{ width: "177px", fontFamily: "Roboto", fontSize: "13px" }}
            type="time"
            onChange={(ev) => {
              handleUpdateState(
                ev.target.value,
                "xtm.hotFolders.detectContentSpecificTime"
              );
            }}
            value={form.xtm.hotFolders.detectContentSpecificTime}
          />
        </FormRow>
        <FormRow contained bottomSpaceLow groupInner>
          <InputLabel lead>
            {t("connectionProfiles.form.xtm.continuousLocalisation.label")}
          </InputLabel>
          <StyledCheckbox
            checked={form.xtm.hotFolders.continuousLocalisation}
            onChange={(ev) =>
              handleUpdateCheckbox(ev, "xtm.hotFolders.continuousLocalisation")
            }
            color="success"
          />
        </FormRow>
      </BorderedSection>
      <BorderedSection inner spaceTop="4rem" name="Email notifications">
        <FormFlexRow>
          <StyledCheckbox
            checked={form.xtm.hotFolders.projectCreatedNotify}
            onChange={(ev) =>
              handleUpdateCheckbox(ev, "xtm.hotFolders.projectCreatedNotify")
            }
            color="success"
            sx={{ marginRight: "1.1rem" }}
          />
          <InputLabel lead>
            {t("connectionProfiles.form.xtm.projectCreatedNotification.label")}
          </InputLabel>
        </FormFlexRow>
        <FormFlexRow>
          <StyledCheckbox
            checked={form.xtm.hotFolders.projectFinishedNotify}
            onChange={(ev) =>
              handleUpdateCheckbox(ev, "xtm.hotFolders.projectFinishedNotify")
            }
            color="success"
            sx={{ marginRight: "1.1rem" }}
          />
          <InputLabel lead>
            {t("connectionProfiles.form.xtm.projectFinishedNotification.label")}
          </InputLabel>
        </FormFlexRow>
        <FormFlexRow>
          <StyledCheckbox
            checked={form.xtm.hotFolders.progressTranslationWorkflowNotify}
            onChange={(ev) =>
              handleUpdateCheckbox(
                ev,
                "xtm.hotFolders.progressTranslationWorkflowNotify"
              )
            }
            color="success"
            sx={{ marginRight: "1.1rem" }}
          />
          <InputLabel lead>
            {t(
              "connectionProfiles.form.xtm.progressTranslationWorkflowNotification.label"
            )}
          </InputLabel>
        </FormFlexRow>
        <FormFlexRow>
          <StyledCheckbox
            checked={form.xtm.hotFolders.projectCancelledNotify}
            onChange={(ev) =>
              handleUpdateCheckbox(ev, "xtm.hotFolders.projectCancelledNotify")
            }
            color="success"
            sx={{ marginRight: "1.1rem" }}
          />
          <InputLabel lead>
            {t(
              "connectionProfiles.form.xtm.projectCancelledNotification.label"
            )}
          </InputLabel>
        </FormFlexRow>
        <FormRow groupInner contained>
          <InputLabel lead>
            {t("connectionProfiles.form.xtm.email.label")}
          </InputLabel>
          <TextInput
            type="email"
            value={form.xtm.hotFolders.emailAddress}
            onChange={(ev) =>
              handleUpdateState(ev.target.value, "xtm.hotFolders.emailAddress")
            }
          />
        </FormRow>
      </BorderedSection>
    </>
  );
};
