export const addConnectionProfileHotFolderMapper = ({ form }) => ({
  integrated_system_name: "Microsoft SharePoint",
  connection_profile_name: form.connectionProfileName,
  connection_type: form.connectionType,
  connection_profile_status: form.active === true ? "ACTIVE" : "INACTIVE",
  client_id: form.clientAssigned,
  client_name: form.clientName,
  xtm_temp_credential: form.xtm.tokenID,
  sharepoint_temp_credential: form.sharePoint.tokenID,
  xtm_customers_ids: [form.xtm.customer],
  sharepoint_source_folder_path: form.sharePoint.sharepointFolderPath,
  sharepoint_target_folder_path: form.sharePoint.sharepointTargetPath,
  template_type: form.xtm.hotFolders.templateType,
  template_id: form.xtm.hotFolders.xtmTemplate,
  xtm_project_name_prefix: form.xtm.hotFolders.xtmProjectNamePrefix,
  default_due_date_ahead_in_days: Number(form.xtm.hotFolders.defaultDueDate),
  automatic_continuous_localisation: form.xtm.hotFolders.continuousLocalisation,
  detect_changes_and_submit_at: form.xtm.hotFolders.detectContentSpecificTime,
  content_synchro_from_sh_interval: Number(
    form.xtm.hotFolders.contentSynchroFromShInterval
  ),
  email_address: form.xtm.hotFolders.emailAddress,
  notify_project_created: form.xtm.hotFolders.projectCreatedNotify,
  notify_job_or_project_finished_and_imported_back_to_sp:
    form.xtm.hotFolders.projectFinishedNotify,
  notify_translation_workflow_progress:
    form.xtm.hotFolders.progressTranslationWorkflowNotify,
  notify_project_cancelled_or_archived:
    form.xtm.hotFolders.projectCancelledNotify,
  file_return_mode: "JOB",
  site_name: form.sharePoint.loginCredentials.sharePointSiteName,
});
