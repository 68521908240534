import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InputLabel,
  FormRow,
  TextInput,
  SubmitButton,
  FormError,
} from "components/global/forms";
import { ReactComponent as XTMIcon } from "assets/xtm-icon.svg";
import { Box } from "@mui/system";

import {
  connectToXtmAsync,
  selectAllXTMFieldsLength,
  selectIsXtmTokenWithoutErrors,
  selectModifyForm,
  selectModifyXtm,
  setField,
} from "data/features/connectionProfiles/connectionProfileSlice";
import { SectionToggle } from "components/SectionToggle";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { handleUpdateState } from "../helpers";

export const XTMConnect = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useSelector(selectModifyForm);
  const { error } = useSelector(selectModifyXtm);
  const xtmFieldsAreFilled = useSelector(selectAllXTMFieldsLength);
  const isXtmTokenWithoutErrors = useSelector(selectIsXtmTokenWithoutErrors);
  const params = useLocation();
  const profileID = Number(new URLSearchParams(params.search).get("id"));
  const [disabledXtmButton, setDisabledXtmButton] = useState(
    isXtmTokenWithoutErrors
  );

  useEffect(() => {
    if (profileID) {
      dispatch(setField({ field: "xtm.isConnected", value: true }));
    }
  }, [profileID, form.xtm]);

  const handleUpdateField = (field) => (ev) => {
    setDisabledXtmButton(false);
    handleUpdateState(ev.target.value, field);
  };

  const handleConnectToXtm = () => {
    setDisabledXtmButton(true);
    const {
      xtm: { loginCredentials },
    } = form;

    dispatch(
      connectToXtmAsync({
        username: loginCredentials.xtmUser,
        password: loginCredentials.xtmPassword,
        companyName: loginCredentials.xtmCompanyName,
        url: loginCredentials.xtmUrl,
        credentialType: "XTM",
      })
    );
  };

  const renderSubmit = () => (
    <SubmitButton
      disabled={Boolean(!xtmFieldsAreFilled || disabledXtmButton)}
      onClick={handleConnectToXtm}
      type="button"
      sx={{ margin: "1.6rem 0 0 0", width: "100%" }}
    >
      <Box mr="0.8rem" sx={{ display: "flex" }}>
        <XTMIcon />
      </Box>
      {t("connectionProfiles.form.xtm.submit")}
    </SubmitButton>
  );

  return (
    <FormRow sx={{ alignItems: "flex-start" }} contained bottomSpace group>
      <InputLabel lead>{t("connectionProfiles.form.xtm.label")}*</InputLabel>
      <SectionToggle
        connected={isXtmTokenWithoutErrors || form.xtm.isConnected}
        renderSubmit={renderSubmit}
      >
        {error && (
          <FormError sx={{ marginTop: 0, marginBottom: "1rem" }}>
            {error}
          </FormError>
        )}
        <InputLabel sx={{ marginTop: 0 }} medium>
          {t("connectionProfiles.form.xtm.url.label")}
        </InputLabel>
        <TextInput
          name="xtmUrl"
          onChange={handleUpdateField("xtm.loginCredentials.xtmUrl")}
          value={form.xtm.loginCredentials.xtmUrl}
        />
        <InputLabel medium>
          {t("connectionProfiles.form.xtm.companyName.label")}
        </InputLabel>
        <TextInput
          name="companyName"
          onChange={handleUpdateField("xtm.loginCredentials.xtmCompanyName")}
          value={form.xtm.loginCredentials.xtmCompanyName}
        />
        <InputLabel medium>
          {t("connectionProfiles.form.xtm.user.label")}
        </InputLabel>
        <TextInput
          name="user"
          onChange={handleUpdateField("xtm.loginCredentials.xtmUser")}
          value={form.xtm.loginCredentials.xtmUser}
        />
        <InputLabel medium>
          {t("connectionProfiles.form.xtm.password.label")}
        </InputLabel>
        <TextInput
          type="password"
          name="password"
          onChange={handleUpdateField("xtm.loginCredentials.xtmPassword")}
          value={form.xtm.loginCredentials.xtmPassword}
        />
      </SectionToggle>
    </FormRow>
  );
};
