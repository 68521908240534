import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { LocalStorageService } from "services";

export const GuestRoute = ({ children }) => {
  if (LocalStorageService.getAuthToken()) {
    return <Navigate to="/clients" replace />;
  }

  return children;
};

GuestRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
