import React, { useEffect, useState } from "react";
import {
  InputLabel,
  FormRow,
  TextInput,
  SubmitButton,
  FormError,
} from "components/global/forms";
import { Tooltip } from "components/Tooltip";
import { BorderedSection } from "components/BorderedSection";
import { SectionToggle } from "components/SectionToggle";
import { DirectoryTree } from "components";
import { ReactComponent as SharePointIcon } from "assets/sharepoint-icon.svg";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { connectToSharePoint } from "data/features/connectionProfiles/connectionProfileApi";
import {
  selectAllSharePointFieldsLength,
  selectModifyForm,
  selectProfileDataSharePoint,
  selectSharePointFolderPath,
  selectSharePointLoginCredentials,
  selectSharePointTargetPath,
  setField,
} from "data/features/connectionProfiles/connectionProfileSlice";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { handleUpdateState } from "../helpers";
import { HOT_FOLDER, WORKING_COPY } from "../constants";

export const SharePointSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useSelector(selectModifyForm);
  const profileDataSharePoint = useSelector(selectProfileDataSharePoint);
  const loginCredentials = useSelector(selectSharePointLoginCredentials);
  const sharePointFieldsFilled = useSelector(selectAllSharePointFieldsLength);
  const sharePointFolderPath = useSelector(selectSharePointFolderPath);
  const sharePointTargetPath = useSelector(selectSharePointTargetPath);
  const params = useLocation();
  const profileID = Number(new URLSearchParams(params.search).get("id"));

  useEffect(() => {
    if (profileID) {
      dispatch(setField({ field: "sharePoint.isConnected", value: true }));
    }
  }, [profileID, form.sharePoint]);

  const shouldDisplayWorkingCopySettings =
    form.connectionType === WORKING_COPY &&
    (profileDataSharePoint.tokenID || form.sharePoint.isConnected);

  const shouldDisplayHotFolderSettings =
    form.connectionType === HOT_FOLDER &&
    (profileDataSharePoint.tokenID || form.sharePoint.isConnected);

  const [disabledSharePointButton, setDisabledSharePointButton] = useState(
    Boolean(profileDataSharePoint.tokenID)
  );
  const [error, setError] = useState(null);

  const handleConnectToSharePoint = () => {
    setError(null);
    setDisabledSharePointButton(true);

    connectToSharePoint({
      clientID: loginCredentials.sharePointClientID,
      clientSecret: loginCredentials.sharePointClientSecret,
      url: loginCredentials.sharePointUrl,
      tenantId: loginCredentials.sharePointTenantId,
      siteName: loginCredentials.sharePointSiteName,
      credentialType: "SHAREPOINT",
    })
      .then((response) => {
        if (response.data.status === 400) {
          setError("Invalid credentials");
          setDisabledSharePointButton(false);
        }
        dispatch(
          setField({ field: "sharePoint.tokenID", value: response.data })
        );
      })
      .catch(() => {
        setError("Invalid credentials");
        setDisabledSharePointButton(false);
      });
  };

  const handleUpdateField = (field) => (ev) => {
    setDisabledSharePointButton(false);
    handleUpdateState(ev.target.value, field);
  };

  const handleUpdateFolderPath = (id) => {
    handleUpdateState(id, "sharePoint.sharepointFolderPath");
  };

  const handleUpdateTargetFolderPath = (id) => {
    handleUpdateState(id, "sharePoint.sharepointTargetPath");
  };

  const renderSubmit = () => (
    <SubmitButton
      disabled={!sharePointFieldsFilled || disabledSharePointButton}
      onClick={handleConnectToSharePoint}
      type="button"
      sx={{ margin: "1.6rem 0 0 0", width: "100%" }}
    >
      <Box mr="0.8rem" sx={{ display: "flex" }}>
        <SharePointIcon />
      </Box>
      {t("connectionProfiles.form.sharepoint.submit")}
    </SubmitButton>
  );

  const tooltipTitle =
    loginCredentials.sharePointClientID?.length > 40
      ? loginCredentials.sharePointClientID
      : "";

  return (
    <BorderedSection name={t("connectionProfiles.form.sharepoint.sectionName")}>
      <FormRow sx={{ alignItems: "flex-start" }} contained group>
        <InputLabel lead>
          {t("connectionProfiles.form.sharepoint.label")}*
        </InputLabel>
        <SectionToggle
          connected={
            profileDataSharePoint.tokenID || form.sharePoint.isConnected
          }
          renderSubmit={renderSubmit}
        >
          {error && (
            <FormError sx={{ marginTop: 0, marginBottom: "1rem" }}>
              {error}
            </FormError>
          )}

          <InputLabel sx={{ marginTop: 0 }} medium>
            {t("connectionProfiles.form.sharepoint.url.label")}
          </InputLabel>
          <TextInput
            name="sharePointUrl"
            autocomplete="on"
            value={loginCredentials.sharePointUrl}
            onChange={handleUpdateField(
              "sharePoint.loginCredentials.sharePointUrl"
            )}
          />
          <InputLabel medium>
            {t("connectionProfiles.form.sharepoint.client.label")}
          </InputLabel>
          <Tooltip title={tooltipTitle} arrow placement="bottom">
            <TextInput
              autocomplete="on"
              name="sharePointClientID"
              value={loginCredentials.sharePointClientID}
              onChange={handleUpdateField(
                "sharePoint.loginCredentials.sharePointClientID"
              )}
            />
          </Tooltip>
          <InputLabel medium>
            {t("connectionProfiles.form.sharepoint.secret.label")}
          </InputLabel>
          <TextInput
            autocomplete="on"
            name="sharePointClientSecret"
            type="password"
            value={loginCredentials.sharePointClientSecret}
            onChange={handleUpdateField(
              "sharePoint.loginCredentials.sharePointClientSecret"
            )}
          />
          <InputLabel medium>
            {t("connectionProfiles.form.sharepoint.tenantId.label")}
          </InputLabel>
          <TextInput
            autocomplete="on"
            name="sharePointTenantId"
            value={loginCredentials.sharePointTenantId}
            onChange={handleUpdateField(
              "sharePoint.loginCredentials.sharePointTenantId"
            )}
          />
          <InputLabel medium>
            {t("connectionProfiles.form.sharepoint.siteName.label")}
          </InputLabel>
          <TextInput
            autocomplete="on"
            name="sharePointSiteName"
            value={loginCredentials.sharePointSiteName}
            onChange={handleUpdateField(
              "sharePoint.loginCredentials.sharePointSiteName"
            )}
          />
        </SectionToggle>
      </FormRow>
      {shouldDisplayWorkingCopySettings && (
        <Box mt={5}>
          <BorderedSection inner name="SharePoint path">
            <DirectoryTree
              tokenID={profileDataSharePoint.tokenID}
              callback={handleUpdateFolderPath}
              selected={sharePointFolderPath}
            />
          </BorderedSection>
        </Box>
      )}
      {shouldDisplayHotFolderSettings && (
        <Box mt={5}>
          <BorderedSection
            inner
            name={t("connectionProfiles.form.sharepoint.sharepointPath.label")}
          >
            <FormRow groupInner sx={{ marginTop: "14px", marginBottom: "8px" }}>
              <InputLabel lead>
                {t(
                  "connectionProfiles.form.sharepoint.sharepointPath.sourceFolder"
                )}
              </InputLabel>
            </FormRow>
            <DirectoryTree
              tokenID={profileDataSharePoint.tokenID}
              callback={handleUpdateFolderPath}
              selected={sharePointFolderPath}
            />
            <FormRow groupInner sx={{ marginTop: "14px", marginBottom: "8px" }}>
              <InputLabel lead>
                {t(
                  "connectionProfiles.form.sharepoint.sharepointPath.targetFolder"
                )}
              </InputLabel>
            </FormRow>
            <DirectoryTree
              tokenID={profileDataSharePoint.tokenID}
              callback={handleUpdateTargetFolderPath}
              selected={sharePointTargetPath}
            />
          </BorderedSection>
        </Box>
      )}
    </BorderedSection>
  );
};
