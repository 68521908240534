import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { LinearProgress } from "@mui/material";
import { TreeView } from "@mui/lab";
import { ReactComponent as ExpandMore } from "assets/arrow-up.svg";
import { ReactComponent as ExpandLess } from "assets/arrow-down.svg";
import { useLocation } from "react-router-dom";
import { selectProfileDataSharePoint } from "data";
import { useSelector } from "react-redux";
import { cache, cachedRequest } from "./cachedRequest";
import { Item } from "./Item";

export const Tree = ({ tokenID, callback, selected }) => {
  const initItem = {
    Name: "Shared Documents",
    ServerRelativeUrl: "/sites/XTM/Shared Documents",
    ItemCount: 1,
    children: [],
  };

  const params = useLocation();
  const profileID = Number(new URLSearchParams(params.search).get("id"));
  const profileDataSharePoint = useSelector(selectProfileDataSharePoint);
  const [loading, setLoading] = useState(initItem.ServerRelativeUrl);
  const [isError, setIsError] = useState(false);

  const [fetchedData, setFetchedData] = useState(null);
  const [activeBranch, setActiveBranch] = useState([]);

  const [fetchedServerRelativeUrl, setFetchedServerRelativeUrl] = useState([]);
  const [activeServerRelativeUrl, setActiveServerRelativeUrl] = useState(
    initItem.ServerRelativeUrl
  );

  const prepareUrl = (url) => url.split("/").slice(3).join("/");

  const addNodeToFetchedServerRelativeUrl = (node) => {
    const { ServerRelativeUrl } = node;
    setFetchedServerRelativeUrl((prev) => [...prev, ServerRelativeUrl]);
    return node;
  };

  const fetchChildren = async (
    directory,
    targetDirectory = "",
    isProfileID = false,
    level = 0
  ) => {
    let children = [];
    if (directory.ItemCount !== 0) {
      const requestID = isProfileID ? profileID : tokenID;
      const url = prepareUrl(directory.ServerRelativeUrl);
      children = await cachedRequest(requestID, url, isProfileID);

      const selectedDirectory = selected
        ?.split("/")
        ?.filter((item) => item !== "");

      const relativeUrlDirectory = directory.ServerRelativeUrl?.split(
        "/"
      )?.filter((item) => item !== "");

      relativeUrlDirectory?.splice(0, 2);
      selectedDirectory?.splice(0, 2);

      // const isInActiveBranch =
      // selected && selected.includes(directory.ServerRelativeUrl);

      const isInActiveBranch =
        selected &&
        relativeUrlDirectory
          ?.map((item, index) => item === selectedDirectory[index])
          ?.every((item) => item === true);
      const isTargetDirectory = targetDirectory === directory.ServerRelativeUrl;
      const isInFetchedServerRelativeUrl = fetchedServerRelativeUrl.includes(
        directory.ServerRelativeUrl
      );

      if (
        isInFetchedServerRelativeUrl ||
        isTargetDirectory ||
        isInActiveBranch
      ) {
        addNodeToFetchedServerRelativeUrl(directory);
        if (isInActiveBranch) {
          setActiveBranch((prev) => [...prev, directory.ServerRelativeUrl]);
        }

        children = await Promise.all(
          children.map((item) =>
            fetchChildren(item, targetDirectory, isProfileID, level + 1)
          )
        );
      }
    }

    // if (children.length === 0 && isProfileID) {
    // 	setIsError(true);
    // }

    return { ...directory, children };
  };

  const fetch = async (isProfileId = false) => {
    const valueWithChildren = await fetchChildren(
      initItem,
      activeServerRelativeUrl,
      isProfileId
    );
    setFetchedData(valueWithChildren);
  };

  useEffect(() => {
    setLoading(activeServerRelativeUrl);
    setIsError(false);

    const isProfileID = profileDataSharePoint.tokenID === null && profileID;

    fetch(isProfileID)
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setLoading(null);
      });
  }, [activeServerRelativeUrl, tokenID]);

  useEffect(() => {
    if (fetchedData !== null && fetchedData?.children?.length === 0) {
      setIsError(true);
    }
  }, [fetchedData]);

  useEffect(() => {
    if (profileDataSharePoint.tokenID !== null && profileID) {
      setActiveServerRelativeUrl(initItem.ServerRelativeUrl);
      setLoading(initItem.ServerRelativeUrl);
      setFetchedData(null);
      setActiveBranch([]);
      setFetchedServerRelativeUrl([]);
      setIsError(false);
      cache.clear();
      fetch(false)
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setLoading(null);
        });
    }
  }, [profileDataSharePoint.tokenID]);

  let isFirstNode = true;
  let isAfterSelectedNode = false;

  const renderTree = (node, parents = [], parent = null) => {
    const isChildren = Array.isArray(node.children);
    if (node.ServerRelativeUrl === selected) isAfterSelectedNode = true;

    return (
      <Item
        key={node.ServerRelativeUrl}
        nodeId={node.ServerRelativeUrl}
        label={node.Name}
        first={isFirstNode}
        last={!node.children}
        parents={parents}
        isParentInActiveBranch={activeBranch.includes(parent)}
        isActiveBranch={activeBranch.includes(node.ServerRelativeUrl)}
        isSelected={selected === node.ServerRelativeUrl}
        isChildren={isChildren}
        isAfterSelected={isAfterSelectedNode}
        isDisabled={loading === node.ServerRelativeUrl}
      >
        {isChildren
          ? node.children.map((childrenNode) => {
              isFirstNode = false;
              return renderTree(
                childrenNode,
                [...parents, node.ServerRelativeUrl],
                node.ServerRelativeUrl
              );
            })
          : null}
      </Item>
    );
  };

  const handleSelect = (event, nodeId) => {
    setActiveBranch(event.parents);
    callback(nodeId);
  };

  const handleNodeToggle = (_, nodeIds) => {
    if (!fetchedServerRelativeUrl.includes(nodeIds[0])) {
      setActiveServerRelativeUrl(nodeIds[0]);
    }
  };

  if (loading === initItem.ServerRelativeUrl) return <LinearProgress />;
  if (isError) return "Error with sharepoint, pleace check credentials.";

  return (
    <TreeView
      sx={{ fontSize: 16 }}
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ExpandLess />}
      selected={selected}
      onNodeSelect={handleSelect}
      onNodeToggle={handleNodeToggle}
    >
      {renderTree(fetchedData)}
    </TreeView>
  );
};

Tree.propTypes = {
  tokenID: PropTypes.number.isRequired,
  selected: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};
