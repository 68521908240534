import {
  DateCell,
  EditCell,
  StatusCell,
} from "components/TableComponent/cells";
import i18n from "config/i18n";

const { t } = i18n;

export const clientColumns = [
  {
    Header: t("column.id"),
    accessor: "id",
    width: "160px",
  },
  {
    Header: t("column.clients.name"),
    accessor: "name",
    width: "250px",
  },
  {
    Header: t("column.status"),
    accessor: "status",
    width: "140px",
    Cell: StatusCell,
  },
  {
    Header: t("column.dateAdded"),
    accessor: "created_date",
    Cell: DateCell,
  },
  {
    Header: t("column.dateModified"),
    accessor: "modification_date",
    Cell: DateCell,
  },
  {
    Header: "",
    accessor: "edit",
    width: "50px",
    canSort: false,
    Cell: EditCell({
      tooltipContent: "Edit client",
      redirectParams: {
        pathname: "/clients/edit",
        searchParam: "id",
      },
    }),
  },
];
