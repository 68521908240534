import { store } from "data/store";

export const updateState = (propertyKey, value) => {
  store.dispatch({
    type: "connectionProfiles/setField",
    payload: {
      field: propertyKey,
      value,
    },
  });
};

export const handleUpdateState = (value, propertyKey) => {
  updateState(propertyKey, value);
};

export const handleUpdateCheckbox = (ev, propertyKey) => {
  const value = ev.target.checked;
  updateState(propertyKey, value);
};

export const isStepChecked = (stepId, workflows) =>
  workflows?.includes(stepId) ?? false;
