import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { LocalStorageService } from "services";
import { useDispatch } from "react-redux";
import { setFilterDate, setFilterProfiles, setFilterTypes } from "data";

export const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const MAIN_ADMIN_ROUTES = [
    "/administrators",
    "/administrators/add",
    "/administrators/edit",
  ];

  const CLIENT_ROUTES = ["/clients", "/clients/add", "/clients/edit", "/"];

  if (!LocalStorageService.getAuthToken()) {
    return <Navigate to="/login" replace />;
  }

  if (LocalStorageService.getUserAuthority() !== "MAIN_ADMINISTRATOR") {
    if (MAIN_ADMIN_ROUTES.includes(location.pathname)) {
      return <Navigate to="/administrators" replace />;
    }
  }

  if (
    LocalStorageService.getUserAuthority() === "CLIENT_ADMINISTRATOR" &&
    CLIENT_ROUTES.includes(location.pathname)
  ) {
    return <Navigate to="/connection-profiles" replace />;
  }

  if (location.pathname !== "/event-log") {
    dispatch(setFilterProfiles([]));
    dispatch(setFilterTypes([]));
    dispatch(
      setFilterDate({
        selection: {
          startDate: new Date(),
          endDate: null,
          key: "selection",
        },
      })
    );
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
