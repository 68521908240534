import { createTheme } from "@mui/material/styles";

export const globalStyles = {
  "*": {
    margin: 0,
    padding: 0,
    boxSizing: "border-box",
  },
  html: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "10px",
  },
};

export const CONTAINER_SETTINGS = {
  maxWidth: "1404px",
  spaceX: "1.6rem",
};

export const theme = createTheme({
  palette: {
    primary: {
      lightBg: "#ccedfa",
      light: "#80d2f1",
      contrastText: "#fff",
      main: "#00a4e4",
    },
    background: {
      light: "#eff3f6",
      main: "#e0e1e3",
      mediumDark: "#dce5ed",
      dark: "#2e3844",
    },
    text: {
      primary: "#333333",
      muted: "#999999",
      light: "#555555",
      disabled: "#a9a9a9",
      main: "#333333",
      darkGray: "#444444",
      dark: "#2e3844",
    },
    border: {
      light: "#ccc",
      main: "#dddddd",
    },
    white: {
      main: "#ffffff",
    },
    success: {
      main: "#38af49",
    },
    error: {
      main: "#ff6347",
    },
  },
});
