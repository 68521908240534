import React from "react";
import StyledSection from "components/global/Section";
import { useTranslation } from "react-i18next";
import * as Styles from "./styles";
import LoginForm from "./components/LoginForm";

function Login() {
  const { t } = useTranslation();

  return (
    <StyledSection type="login" spaceTop>
      <Styles.LoginBox>
        <Styles.LoginBoxHeader>{t("login.box.header")}</Styles.LoginBoxHeader>
        <Styles.LoginBoxContent>
          <LoginForm />
        </Styles.LoginBoxContent>
      </Styles.LoginBox>
    </StyledSection>
  );
}

export default Login;
