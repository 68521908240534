import React from "react";
import { Box } from "@mui/system";
import { Chip } from "@mui/material";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { handleUpdateState } from "../../../helpers";

export const Selected =
  ({ workflowIds, workflowMap }) =>
  (selected) => {
    const handleDelete = (value) => () =>
      handleUpdateState(
        workflowIds.filter((workflow) => workflow !== value),
        "xtm.workflows.workflowIds"
      );

    const handleMouseDown = (event) => {
      event.stopPropagation();
    };

    return (
      <Box
        sx={{
          display: "flex",
          zIndex: 1,
          flexWrap: "wrap",
          gap: 0.5,
        }}
      >
        {selected &&
          // eslint-disable-next-line react/destructuring-assignment
          selected.map((value) => (
            <Chip
              sx={{
                fontSize: "1.2rem",
                height: "auto",
                padding: "3px 0",
              }}
              onDelete={handleDelete(value)}
              deleteIcon={<DeleteIcon />}
              key={value}
              onMouseDown={handleMouseDown}
              label={workflowMap?.get(value)?.name}
            />
          ))}
      </Box>
    );
  };
