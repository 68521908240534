import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { StyledCheckbox } from "components/global/forms";
import { useDispatch, useSelector } from "react-redux";
import { removeRowFromExport, addRowToExport, selectRowsToExport } from "data";
import { Tooltip } from "components/Tooltip";
import { useTranslation } from "react-i18next";

export const SelectAllRowCell = ({ data }) => {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(false);
  const dispatch = useDispatch();
  const rowsToExport = useSelector(selectRowsToExport);

  const handleChange = () => {
    setIsSelected((prevState) => !prevState);
  };

  useEffect(() => {
    if (isSelected) {
      data.forEach(({ id }) => {
        dispatch(addRowToExport(id));
      });
    } else {
      data.forEach(({ id }) => {
        dispatch(removeRowFromExport(id));
      });
    }
  }, [isSelected]);

  useEffect(() => {
    if (isSelected && rowsToExport.length === 0) {
      setIsSelected(false);
    }
  }, [rowsToExport]);

  return (
    <Tooltip title={t("column.selectAllTooltip")} arrow placement="bottom">
      <StyledCheckbox
        onChange={handleChange}
        checked={isSelected}
        color="success"
      />
    </Tooltip>
  );
};

SelectAllRowCell.propTypes = {
  data: PropTypes.array.isRequired,
};
