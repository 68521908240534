import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { InputLabel, TextInput, FormError } from "components/global/forms";
import { fetchLogin } from "data/features/auth/authApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "data/features/auth/authSlice";
import { handleLogin } from "helpers/handleLogin";
import { useTranslation } from "react-i18next";
import * as Styles from "../styles";

function LoginForm() {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    setError,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsDisabled(true);
    fetchLogin(data)
      .then((response) => {
        handleLogin(response);
        dispatch(login());
        if (response.data.user.authority === "MAIN_ADMINISTRATOR") {
          navigate("/clients", { replace: true });
        } else {
          navigate("/connection-profiles", { replace: true });
        }
        return response;
      })
      .catch((error) => {
        setError("email", {
          type: "custom",
          message: error.response?.data?.message,
        });
        setError("password", { type: "custom" });
        return error;
      })
      .then(() => setIsDisabled(false));
  };
  useEffect(() => {
    const subscription = watch((value) => {
      const areFieldsFilled = value.email.length && value.password.length;
      setIsDisabled(!areFieldsFilled);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormError>
        <span>{errors.email?.message}</span>
      </FormError>
      <FormError>
        <span>{errors.password?.message}</span>
      </FormError>
      <InputLabel>{t("form.email.label")}</InputLabel>
      <TextInput
        type="email"
        aria-label="Email"
        hasError={errors.email}
        {...register("email", {
          required: t("form.email.required"),
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: t("form.email.invalid"),
          },
        })}
      />
      <InputLabel>{t("form.password.label")}</InputLabel>
      <TextInput
        type="password"
        aria-label="Password"
        hasError={errors.password}
        {...register("password", {
          required: t("form.password.required"),
        })}
      />
      <Styles.ForgotPasswordLink
        aria-label="ForgotPasswordButton"
        to="/forgot-password"
      >
        {t("login.form.forgot")}
      </Styles.ForgotPasswordLink>
      <Styles.LoginSubmitButton aria-label="LoginButton" disabled={isDisabled}>
        {t("login.form.submit.label")}
      </Styles.LoginSubmitButton>
    </form>
  );
}

export default LoginForm;
