import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";
import StyledSection from "components/global/Section";
import { InnerSectionContainer } from "components/global/Container";
import {
  InputLabel,
  FormRow,
  TextInput,
  StyledCheckbox,
  CancelButton,
  SubmitButton,
} from "components/global/forms";
import { fetchClients } from "data/features/clients/clientsApi";
import { WhiteSelect } from "components/Select";
import {
  editAdministrator,
  fetchAdministratorById,
} from "data/features/administrators/administratorsApi";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Heading, ButtonsContainer, EditClientText } from "../styles";

const EditAdministrator = () => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [clients, setClients] = useState(null);
  const [admin, setAdmin] = useState(null);
  const params = useLocation();
  const paramID = Number(new URLSearchParams(params.search).get("id"));
  const isEditPage = !!paramID;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (paramID) {
      fetchAdministratorById(paramID)
        .then((response) => {
          setAdmin(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
      fetchClients({ limit: 999, isDropdown: true })
        .then((res) => setClients(res.data.objects))
        .catch((err) => toast.error(err));
    } else {
      navigate("/administrators", { replace: true });
    }
  }, []);

  const onSubmit = (data) => {
    const { clientAssigned, status, email, password, confirmPassword } = data;

    if (password !== confirmPassword) {
      setError("password", "custom", t("form.confirmPassword.match"));
      setError("confirmPassword", "custom", t("form.confirmPassword.match"));
      toast.error(t("form.confirmPassword.match"));
      return;
    }

    setIsDisabled(true);
    editAdministrator({
      id: paramID,
      email,
      clientAssigned,
      password,
      status: status ? "ACTIVE" : "NOT_ACTIVE",
    })
      .then((response) => {
        navigate("/administrators", { replace: true });
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        return error;
      });
  };

  useEffect(() => {
    const subscription = watch((value) => {
      let areFieldsFilled;
      if (isEditPage) {
        areFieldsFilled = value.email.length && value.clientAssigned;
      } else {
        areFieldsFilled =
          value.email.length &&
          value.password.length &&
          value.confirmPassword.length &&
          value.clientAssigned;
      }
      setIsDisabled(!areFieldsFilled);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const confirmPasswordOptions = () => {
    if (isEditPage) return {};
    return {
      required: paramID ? undefined : t("form.clientAssigned.required"),
    };
  };

  return (
    <StyledSection type="login">
      <InnerSectionContainer>
        <Heading>{t("editAdministrator.heading")}</Heading>
        {clients && admin && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormRow bottomSpace>
              <InputLabel lead>{t("form.email.label")}*</InputLabel>
              <TextInput
                defaultValue={admin.email}
                hasError={errors.email}
                {...register("email", {
                  required: t("form.email.required"),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("form.email.invalid"),
                  },
                })}
              />
            </FormRow>
            <FormRow bottomSpace>
              <InputLabel lead>{t("form.password.label")}*</InputLabel>
              <TextInput
                type="password"
                hasError={errors.password}
                {...register("password")}
              />
            </FormRow>
            <FormRow bottomSpace>
              <InputLabel lead>{t("form.confirmPassword.label")}*</InputLabel>
              <TextInput
                type="password"
                hasError={errors.confirmPassword}
                {...register("confirmPassword", confirmPasswordOptions())}
              />
            </FormRow>
            <FormRow bottomSpace>
              <InputLabel lead>{t("form.clientAssigned.label")}*</InputLabel>
              <WhiteSelect
                defaultValue={admin.client}
                {...register("clientAssigned", {
                  required: t("form.clientAssigned.required"),
                })}
                label="Choose..."
              >
                {clients?.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </WhiteSelect>
            </FormRow>
            <FormRow bottomSpace>
              <InputLabel lead>{t("form.active.label")}</InputLabel>
              <div>
                <StyledCheckbox
                  {...register("status")}
                  defaultChecked={admin?.status === "ACTIVE"}
                  color="success"
                />
              </div>
            </FormRow>
            <FormRow bottomSpace>
              <InputLabel lead>{t("form.dateAdded")}</InputLabel>
              <EditClientText>
                {format(new Date(admin?.created_date), "dd.MM.yyyy kk:mm")}
              </EditClientText>
            </FormRow>
            {admin?.modification_date && (
              <FormRow>
                <InputLabel lead>{t("form.dateModified")}</InputLabel>
                <EditClientText>
                  {format(
                    new Date(admin?.modification_date),
                    "dd.MM.yyyy kk:mm"
                  )}
                </EditClientText>
              </FormRow>
            )}
            <ButtonsContainer>
              <CancelButton to="/administrators">
                {t("form.cancel")}
              </CancelButton>
              <SubmitButton disabled={isDisabled} type="submit">
                {t("form.save")}
              </SubmitButton>
            </ButtonsContainer>
          </form>
        )}
      </InnerSectionContainer>
    </StyledSection>
  );
};

export default EditAdministrator;
