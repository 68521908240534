import React from "react";
import { useSelector } from "react-redux";
import { MenuItem, WhiteSelect } from "components/Select";
import {
  selectModifyForm,
  selectModifyWorkflows,
  selectWorkflowsMap,
} from "data";

import { handleUpdateState } from "../../../helpers";
import { Selected } from "./Selected";

export const SelectWorkflowName = () => {
  const data = useSelector(selectModifyForm);
  const workflows = useSelector(selectModifyWorkflows);
  const workflowMap = useSelector(selectWorkflowsMap);

  const selectedWorkflows = Selected({
    workflowIds: data.xtm.workflows?.workflowIds || [],
    workflowMap,
  });

  const handleChange = (ev) =>
    handleUpdateState(ev.target.value, "xtm.workflows.workflowIds");

  return (
    <WhiteSelect
      multiple
      value={data.xtm.workflows?.workflowIds || []}
      onChange={handleChange}
      renderValue={selectedWorkflows}
    >
      {workflows.data &&
        workflows.data.workflows?.map((workflow) => (
          <MenuItem key={workflow.id} value={workflow.id}>
            {workflow.name}
          </MenuItem>
        ))}
    </WhiteSelect>
  );
};
