import { styled } from "@mui/material";
import { InputLabel } from "components/global/forms";

export const StepsList = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 308px;
  overflow: auto;
  padding: 0 1.6rem 1.6rem;
`;

export const StepItem = styled("div")`
  margin-top: 1.2rem;
`;

export const StepItemLabel = styled(InputLabel)`
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
`;
