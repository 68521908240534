import { get } from "lodash";

const WORKING_COPY_REQUIRED_FIELDS = [
  "connectionProfileName.length",
  "connectionType",
  "clientAssigned",
  "sharePoint.sharepointFolderPath",
  "xtm.checkProjectMinutes",
  "xtm.customers.length",
  "xtm.workflows.workflowIds.length",
];

const HOT_FOLDER_REQUIRED_FIELDS = [
  "connectionProfileName.length",
  "connectionType",
  "clientAssigned",
  "sharePoint.sharepointFolderPath",
  "sharePoint.sharepointTargetPath",
  "xtm.customer",
  "xtm.hotFolders.templateType",
  "xtm.hotFolders.xtmTemplate",
  "xtm.hotFolders.xtmProjectNamePrefix.length",
  "xtm.hotFolders.defaultDueDate",
  "xtm.hotFolders.contentSynchroFromShInterval",
  "xtm.hotFolders.detectContentSpecificTime",
];

export const allRequiredFieldsFilledWorkingCopy = (form) =>
  WORKING_COPY_REQUIRED_FIELDS.every(
    (field) =>
      !!get(form, field) &&
      (form.xtm.tokenID || form.xtm.isConnected) &&
      (form.sharePoint.tokenID || form.sharePoint.isConnected)
  );

export const allRequiredFieldsFilledHotFolder = (form) =>
  HOT_FOLDER_REQUIRED_FIELDS.every(
    (field) =>
      !!get(form, field) &&
      (form.xtm.tokenID || form.xtm.isConnected) &&
      (form.sharePoint.tokenID || form.sharePoint.isConnected)
  );
