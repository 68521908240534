import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/system";
import { CONTAINER_SETTINGS } from "config/theme";

export const TabBar = styled("div")`
  display: flex;
  width: 100%;
  padding: 0 ${CONTAINER_SETTINGS.spaceX};
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
  background-color: ${({ theme }) => theme.palette.white.main};
`;

export const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    height: 4px;
  }
`;

export const StyledTab = styled(Tab)`
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 400;
  text-transform: none;
  color: ${({ theme }) => theme.palette.text.dark};
  margin-right: 4rem;
`;

export const Heading = styled("h1")`
  font-size: 1.7rem;
  line-height: 2rem;
  font-weight: 400;
  padding: 2.4rem 0;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const ButtonsContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4.8rem;
`;

export const EditClientText = styled("div")`
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.palette.text.main};
`;

export const FixedActionsBar = styled("div")`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.white.main};
  z-index: 9999;
  border-top: 1px solid ${({ theme }) => theme.palette.border.main};
`;
