import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { noop } from "lodash";
import { Tree } from "./Tree";

const Wrapper = styled("div")`
  padding: 16px;
  margin: 0 16px;
  background-color: white;
  border: 1px solid #dddddd;
`;

export const DirectoryTree = ({ tokenID, callback, selected }) => (
  <Wrapper>
    <Tree callback={callback} tokenID={tokenID} selected={selected} />
  </Wrapper>
);

DirectoryTree.propTypes = {
  tokenID: PropTypes.number.isRequired,
  selected: PropTypes.string.isRequired,
  callback: PropTypes.func,
};

DirectoryTree.defaultProps = {
  callback: noop,
};
