const XTM_USER_TOKEN = "XTM_USER_TOKEN";
const XTM_USER_REFRESH_TOKEN = "XTM_USER_REFRESH_TOKEN";
const XTM_USER_AUTHORITY = "XTM_USER_AUTHORITY";

export class LocalStorageService {
  static getAuthToken = () => localStorage.getItem(XTM_USER_TOKEN);

  static setAuthToken = (token) => {
    localStorage.setItem(XTM_USER_TOKEN, token);

    return true;
  };

  static clearAuthToken = () => {
    localStorage.removeItem(XTM_USER_TOKEN);

    return true;
  };

  static getRefreshToken = () => localStorage.getItem(XTM_USER_REFRESH_TOKEN);

  static setRefreshToken = (token) => {
    localStorage.setItem(XTM_USER_REFRESH_TOKEN, token);

    return true;
  };

  static setUserAuthority = (authority) => {
    localStorage.setItem(XTM_USER_AUTHORITY, authority);

    return true;
  };

  static clearUserAuthority = () => {
    localStorage.removeItem(XTM_USER_AUTHORITY);

    return true;
  };

  static getUserAuthority = () => localStorage.getItem(XTM_USER_AUTHORITY);
}
