import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { selectModifyForm, selectModifyWorkflows } from "data";
import { SearchInput } from "components/global/forms";
import { useTranslation } from "react-i18next";
import { StepsList } from "./styles";

export const WorkflowSteps = ({ renderStep = () => {} }) => {
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const form = useSelector(selectModifyForm);
  const { data } = useSelector(selectModifyWorkflows);

  const workflowIds = get(form, "xtm.workflows.workflowIds", []);

  const handleSearch = (ev) => {
    setSearch(ev.target.value);
  };

  const listWorkflows = () => {
    if (search.length) {
      return workflowIds.filter((id) => {
        const { name } = data.mappedWorkflows.get(id);
        return name.toLowerCase().includes(search.toLowerCase());
      });
    }
    return workflowIds;
  };

  return (
    <div>
      <SearchInput
        variant="standard"
        value={search}
        onChange={handleSearch}
        placeholder={t(
          "connectionProfiles.form.xtm.workflows.searchPlaceholder"
        )}
      />
      <StepsList>{renderStep(listWorkflows())}</StepsList>
    </div>
  );
};

WorkflowSteps.propTypes = {
  renderStep: PropTypes.func,
};

WorkflowSteps.defaultProps = {
  renderStep: () => {},
};
