import PropTypes from "prop-types";
import {
  WORKING_COPY,
  HOT_FOLDER,
} from "pages/Panel/ConnectionProfiles/constants";

export const ConnectionTypeCell = ({ value }) => {
  switch (value) {
    case WORKING_COPY:
      return "Working copy";
    case HOT_FOLDER:
      return "Hot folder";
    default:
      return null;
  }
};

ConnectionTypeCell.propTypes = {
  value: PropTypes.string.isRequired,
};
