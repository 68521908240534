import React from "react";
import * as Styles from "pages/Panel/styles";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { MAIN_ADMINISTRATOR } from "data/features/auth/constants";
import { LocalStorageService } from "services";
import Container from "components/global/Container";
import { useTranslation } from "react-i18next";

function TabsBar({ value }) {
  const userAuthority = LocalStorageService.getUserAuthority();
  const { t } = useTranslation();

  return (
    <Styles.TabBar>
      <Container>
        <Styles.StyledTabs sx={{ marginLeft: "1.6rem" }} value={value}>
          {userAuthority === MAIN_ADMINISTRATOR && (
            <Styles.StyledTab
              value="/clients"
              to="/clients"
              label={t("tabs.clients")}
              component={Link}
            />
          )}
          {userAuthority === MAIN_ADMINISTRATOR && (
            <Styles.StyledTab
              value="/administrators"
              to="/administrators"
              label={t("tabs.administrators")}
              component={Link}
            />
          )}

          <Styles.StyledTab
            value="/connection-profiles"
            label={t("tabs.connectionProfiles")}
            to="/connection-profiles"
            component={Link}
          />
          <Styles.StyledTab
            value="/event-log"
            to="/event-log"
            label={t("tabs.eventLogs")}
            component={Link}
          />
        </Styles.StyledTabs>
      </Container>
    </Styles.TabBar>
  );
}

TabsBar.propTypes = {
  value: PropTypes.string.isRequired,
};

export default TabsBar;
