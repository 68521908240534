import React from "react";
import { styled } from "@mui/material/styles";
import MUITooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const Tooltip = styled(({ className, children, ...props }) => (
  <MUITooltip {...props} classes={{ popper: className }}>
    {children}
  </MUITooltip>
))`
  & .${tooltipClasses.tooltip} {
    background-color: ${({ theme }) => theme.palette.common.black};
    color: ${({ theme }) => theme.palette.common.white};
    padding: 8px;
    font-size: 11px;
  }

  & .${tooltipClasses.arrow} {
    color: ${({ theme }) => theme.palette.common.black};
  }

  ${({ hidden }) => hidden && `display:none;`}

  ${({ fullWidth }) =>
    fullWidth &&
    `
    & .${tooltipClasses.tooltip} {
      width: 100%;
      max-width: 414px;
    }
  `}
`;
