import { LocalStorageService } from "services";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { logout } from "data/features/auth/authSlice";
import { clearClients } from "data/features/clients/clientsSlice";
import { clearAdministrators } from "data/features/administrators/administratorsSlice";
import { clearEventLog } from "data/features/eventLogs/eventLogsSlice";
import { clearConnectionProfile } from "data/features/connectionProfiles/connectionProfileSlice";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    LocalStorageService.clearAuthToken();
    LocalStorageService.clearUserAuthority();
    dispatch(logout());
    dispatch(clearClients());
    dispatch(clearAdministrators());
    dispatch(clearEventLog());
    dispatch(clearConnectionProfile());
  }, []);

  return <Navigate to="/login" replace />;
};

export default Logout;
