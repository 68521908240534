import axios from "axios";

export const cache = new Map();

const key = (folderRoot, tokenID) => `${folderRoot}-${tokenID}`;

export const cachedRequest = (
  id,
  folderRoot = "Shared Documents",
  isProfileId = false
) => {
  const itemKey = key(folderRoot, id);
  if (cache.has(itemKey)) return cache.get(itemKey);

  return axios
    .get(`/sharepoint/folder/path/${isProfileId ? "profile" : "temp"}/${id}`, {
      params: {
        folderRoot,
      },
    })
    .then((response) => {
      const value = response.data.value ?? [];
      cache.set(itemKey, value);
      return value;
    });
};
