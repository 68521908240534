import React from "react";
import Header from "components/Header";
import { Routes, Route } from "react-router-dom";
import Login from "pages/Login/Login";
import ForgotPassword from "pages/Login/ForgotPassword";
import ChangePassword from "pages/Login/ChangePassword";
import Administrators from "pages/Panel/Administrators/Administrators";
import Panel from "pages/Panel";
import { EventLog } from "pages/Panel/EventLog";
import Logout from "pages/Logout";
import { ProtectedRoute, GuestRoute } from "components/routes";
import { AddClient, EditClient, Clients } from "pages/Panel/Clients";
import AddAdministrator from "pages/Panel/Administrators/AddAdministrator";
import EditAdministrator from "pages/Panel/Administrators/EditAdministrator";
import {
  CONNECTION_PROFILE_FORM_TYPES,
  ConnectionProfileForm,
  ConnectionProfiles,
} from "pages/Panel/ConnectionProfiles";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Panel tab={<Clients />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients"
          element={
            <ProtectedRoute>
              <Panel tab={<Clients />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients/add"
          element={
            <ProtectedRoute>
              <AddClient />
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients/edit"
          element={
            <ProtectedRoute>
              <EditClient />
            </ProtectedRoute>
          }
        />
        <Route
          path="/administrators"
          element={
            <ProtectedRoute>
              <Panel tab={<Administrators />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/administrators/add"
          element={
            <ProtectedRoute>
              <AddAdministrator />
            </ProtectedRoute>
          }
        />
        <Route
          path="/administrators/edit"
          element={
            <ProtectedRoute>
              <EditAdministrator />
            </ProtectedRoute>
          }
        />
        <Route
          path="/connection-profiles"
          element={
            <ProtectedRoute>
              <Panel tab={<ConnectionProfiles />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/connection-profiles/add"
          element={
            <ProtectedRoute>
              <ConnectionProfileForm type={CONNECTION_PROFILE_FORM_TYPES.ADD} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/connection-profiles/edit"
          element={
            <ProtectedRoute>
              <ConnectionProfileForm
                type={CONNECTION_PROFILE_FORM_TYPES.EDIT}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/connection-profiles/clone"
          element={
            <ProtectedRoute>
              <ConnectionProfileForm
                type={CONNECTION_PROFILE_FORM_TYPES.CLONE}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/event-log"
          element={
            <ProtectedRoute>
              <Panel tab={<EventLog />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/login"
          element={
            <GuestRoute>
              <Login />
            </GuestRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <GuestRoute>
              <ForgotPassword />
            </GuestRoute>
          }
        />
        <Route
          path="/change-password/:key"
          element={
            <GuestRoute>
              <ChangePassword />
            </GuestRoute>
          }
        />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </div>
  );
}

export default App;
