import React, { useState } from "react";
import { Modal, Fade, Button } from "@mui/material";
import { Box, styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { selectModal, setModal } from "data";
import PropTypes from "prop-types";
import { ReactComponent as CopyClipboardIcon } from "assets/copy-clipboard-icon.svg";
import { SubmitButton } from "components/global/forms";
import toast from "react-hot-toast";

const InnerModal = styled(Box)`
  width: 100%;
  max-width: 685px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  border-radius: 4px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.19), 0px 6px 6px rgba(0, 0, 0, 0.23);
  background-color: ${({ theme }) => theme.palette.white.main};
`;

export const InnerModalCopyButton = styled(Button)`
  position: fixed;
  z-index: 10;
  right: 32px;
  bottom: 83px;
  background-color: ${({ theme }) => theme.palette.white.main};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease;
  &:hover {
    background-color: ${({ theme }) => theme.palette.white.main};
  }
  ${({ visible }) => visible && `opacity:1;pointer-events:auto;`}
`;

export const InnerModalHeading = styled("div")`
  padding: 1.6rem;
  font-size: 1.5rem;
  font-weight: 500;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const InnerModalContent = styled("div")`
  font-size: 1.3rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 1.6rem;
  max-height: 405px;
  overflow: auto;
  position: relative;
`;

export const InnerModalFooter = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.palette.border.main};
  padding: 1.6rem;
`;

export const CloseModalButton = styled(SubmitButton)`
  margin: 0;
  min-width: 52px;
  min-height: 32px;
  padding: 0;
`;

export const EventLogsModal = ({ message }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const modal = useSelector(selectModal);
  const modalOpen = modal.isOpen;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(message);
    toast("Stack trace copied");
  };

  const handleMouseEnter = () => {
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() =>
        dispatch(
          setModal({
            isOpen: false,
          })
        )
      }
      closeAfterTransition
    >
      <Fade in={modalOpen}>
        <InnerModal>
          <InnerModalHeading>Stack trace</InnerModalHeading>
          <InnerModalContent
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {message}
            <InnerModalCopyButton
              onClick={handleCopyToClipboard}
              visible={visible}
            >
              <CopyClipboardIcon />
            </InnerModalCopyButton>
          </InnerModalContent>
          <InnerModalFooter>
            <CloseModalButton
              onClick={() =>
                dispatch(
                  setModal({
                    isOpen: false,
                  })
                )
              }
              type="button"
            >
              Close
            </CloseModalButton>
          </InnerModalFooter>
        </InnerModal>
      </Fade>
    </Modal>
  );
};

EventLogsModal.propTypes = {
  message: PropTypes.string.isRequired,
};
