import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import StyledSection from "components/global/Section";
import { InnerSectionContainer } from "components/global/Container";
import {
  InputLabel,
  FormRow,
  TextInput,
  StyledCheckbox,
  CancelButton,
  SubmitButton,
} from "components/global/forms";
import {
  editClient,
  fetchClientById,
  isConnectionTypeDropdownEnabled,
} from "data/features/clients/clientsApi";
import { useTranslation } from "react-i18next";
import { Heading, ButtonsContainer, EditClientText } from "../styles";

export const EditClient = () => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isEnabledConnectionTypeDropdown, setIsEnabledConnectionTypeDropdown] =
    useState(false);
  const [client, setClient] = useState(null);
  const params = useLocation();
  const paramID = Number(new URLSearchParams(params.search).get("id"));
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (paramID) {
      fetchClientById(paramID)
        .then((response) => {
          setClient(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });

      isConnectionTypeDropdownEnabled()
        .then((response) =>
          setIsEnabledConnectionTypeDropdown(response.data.isWorkingCopyEnabled)
        )
        .catch((error) => {
          console.error(error);
          setIsEnabledConnectionTypeDropdown(false);
        });
    } else {
      navigate("/clients", { replace: true });
    }
  }, []);

  const onSubmit = (data) => {
    setIsDisabled(true);
    editClient({
      id: paramID,
      name: data.clientName.trim(),
      status: data.status ? "ACTIVE" : "NOT_ACTIVE",
      showWorkingCopy: data.showWorkingCopy,
    })
      .then((response) => {
        navigate("/clients", { replace: true });
        return response;
      })
      .catch((error) => {
        setError("clientName", {
          type: "custom",
        });
        toast.error(error.response.data.message);
        return error;
      });
  };

  useEffect(() => {
    const subscription = watch((value) => {
      const isFieldFilled = value.clientName.trim().length;
      setIsDisabled(!isFieldFilled);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <StyledSection type="login">
      <InnerSectionContainer>
        <Heading>{t("editClient.heading")}</Heading>
        {client && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormRow bottomSpace>
              <InputLabel lead>{t("addClient.form.name.label")}</InputLabel>
              <TextInput
                aria-label="ClientName"
                defaultValue={client?.name}
                hasError={errors.clientName}
                {...register("clientName")}
              />
            </FormRow>
            <FormRow bottomSpace>
              <InputLabel lead>{t("form.active.label")}</InputLabel>
              <div>
                <StyledCheckbox
                  {...register("status")}
                  defaultChecked={client?.status === "ACTIVE"}
                  color="success"
                />
              </div>
            </FormRow>
            {isEnabledConnectionTypeDropdown && (
              <FormRow bottomSpace>
                <InputLabel lead>
                  {t("form.showConnectionTypeDropdown.label")}
                </InputLabel>
                <div>
                  <StyledCheckbox
                    {...register("showWorkingCopy")}
                    defaultChecked={client?.showWorkingCopy === true}
                    color="success"
                  />
                </div>
              </FormRow>
            )}
            <FormRow bottomSpace>
              <InputLabel lead>{t("form.dateAdded")}</InputLabel>
              <EditClientText>
                {format(new Date(client?.created_date), "dd.MM.yyyy kk:mm")}
              </EditClientText>
            </FormRow>
            {client?.modification_date && (
              <FormRow>
                <InputLabel lead>{t("form.dateModified")}</InputLabel>
                <EditClientText>
                  {format(
                    new Date(client?.modification_date),
                    "dd.MM.yyyy kk:mm"
                  )}
                </EditClientText>
              </FormRow>
            )}
            <ButtonsContainer>
              <CancelButton aria-label="CancelAddClientButton" to="/clients">
                {t("form.cancel")}
              </CancelButton>
              <SubmitButton disabled={isDisabled} type="submit">
                {t("form.save")}
              </SubmitButton>
            </ButtonsContainer>
          </form>
        )}
      </InnerSectionContainer>
    </StyledSection>
  );
};
