import axios from "axios";
import { EnvService } from "services";

export const fetchLogin = ({ email = "", password = "" } = {}) =>
  axios.post("auth/login", {
    email: String(email),
    password: String(password),
  });

export const fetchForgotPassword = ({ email = "" } = {}) =>
  axios.post("auth/forgot-password", null, {
    params: {
      email: String(email),
    },
  });

export const fetchChangePassword = ({ key = "", newPassword = "" } = {}) =>
  axios.post("auth/change-password", {
    key: String(key),
    new_password: String(newPassword),
  });

export const fetchRefreshToken = (token) =>
  fetch(`${EnvService.getApiUrl()}/auth/refresh-token`, {
    method: "POST",
    body: JSON.stringify({
      refresh_token: String(token),
    }),
  });
