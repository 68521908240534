import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ConnectionIcon } from "assets/connection-icon.svg";
import { ReactComponent as ChevronIcon } from "assets/chevron-icon.svg";
import { useTranslation } from "react-i18next";
import {
  Content,
  DropdownIcon,
  ConnectionText,
  Heading,
  Wrapper,
} from "./styles";

export const SectionToggle = ({
  connected = false,
  customHeading,
  contentNoPadding,
  children,
  renderSubmit,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const renderHeading = () => {
    if (customHeading) {
      return customHeading;
    }

    return (
      <>
        <ConnectionIcon />
        <ConnectionText>
          {connected
            ? t("connectionProfiles.connected")
            : t("connectionProfiles.disconnected")}
        </ConnectionText>
      </>
    );
  };

  return (
    <Wrapper>
      <Heading connected={connected} onClick={toggleOpen}>
        {renderHeading()}
        <DropdownIcon rotate={isOpen}>
          <ChevronIcon />
        </DropdownIcon>
      </Heading>
      {isOpen && (
        <Content noPadding={contentNoPadding}>
          {children}
          {renderSubmit && renderSubmit()}
        </Content>
      )}
    </Wrapper>
  );
};

SectionToggle.propTypes = {
  connected: PropTypes.bool,
  children: PropTypes.node,
  renderSubmit: PropTypes.func,
  customHeading: PropTypes.func,
  contentNoPadding: PropTypes.bool,
};

SectionToggle.defaultProps = {
  connected: false,
  children: null,
  renderSubmit: null,
  customHeading: null,
  contentNoPadding: false,
};
