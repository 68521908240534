import { Button, styled } from "@mui/material";

export const HeadingActionButton = styled(Button)`
  color: ${({ theme }) => theme.palette.text.light};
  font-size: 14px;
  text-transform: none;

  ${({ customDisabled, theme }) =>
    customDisabled &&
    `
        cursor:not-allowed;
        color: ${theme.palette.text.disabled};
        svg,path {
          fill: ${theme.palette.text.disabled};
        }

      `}

  svg {
    margin-right: 0.4rem;
    path {
      transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  ${({ active, theme }) =>
    active &&
    `
    background-color: ${theme.palette.primary.lightBg};
    color: ${theme.palette.primary.main};
    
    svg {
      path{ 
        fill: ${theme.palette.primary.main};
      }
    }
    `}
`;
