import { setAuthorizationToken } from "config/axios";
import { LocalStorageService } from "services";

export const handleLogin = (response) => {
  LocalStorageService.setAuthToken(response.data.token);
  LocalStorageService.setRefreshToken(response.data.refresh_token);
  LocalStorageService.setUserAuthority(response.data.user.authority);

  setAuthorizationToken();
};
