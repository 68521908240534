import React from "react";
import PropTypes from "prop-types";
import { differenceBy } from "lodash";
import { Chip, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { StyledCheckbox } from "components/global/forms";
import { SelectOptionText, WhiteSelect } from "components/Select";
import { useSelector } from "react-redux";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { handleUpdateState, isStepChecked } from "../../../helpers";
import { StepItem, StepItemLabel } from "./styles";
import { selectWorkflowsMap } from "../../../../../../data";

export const PROCESSING_TYPES = {
  preProcessingSteps: "preProcessingSteps",
  ignoredSteps: "ignoredSteps",
  nonCatToolSteps: "nonCatToolSteps",
};

export const processingSteps =
  (type = "") =>
  (data) => {
    const workflows = useSelector(
      (state) => state.connectionProfiles.modify.form.xtm.workflows
    );
    const workflowMap = useSelector(selectWorkflowsMap);

    const handleMouseDown = (event) => {
      event.stopPropagation();
    };

    // eslint-disable-next-line react/no-unstable-nested-components
    const renderValue = (workflow) => (selected) =>
      (
        <Box
          sx={{
            display: "flex",
            zIndex: 1,
            flexWrap: "wrap",
            gap: 0.5,
          }}
        >
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {selected.map((value) => {
            const label = workflowMap
              ?.get(workflow.id)
              ?.mappedSteps.get(value)?.name;

            const handleClick = () =>
              handleUpdateState(
                workflows?.[type][workflow.id].filter(
                  (singleWorkflow) => singleWorkflow !== value
                ),
                `xtm.workflows.${type}.${workflow.id}`
              );

            return (
              <Chip
                sx={{
                  fontSize: "1.3rem",
                  height: "auto",
                  padding: "3px 0",
                }}
                onMouseDown={handleMouseDown}
                onDelete={handleClick}
                deleteIcon={<DeleteIcon />}
                key={value}
                label={label}
              />
            );
          })}
        </Box>
      );

    const prepareMultiselectValues = (workflow) =>
      Object.values(PROCESSING_TYPES)
        .filter((t) => t !== type)
        .map((typeName) =>
          workflows?.[typeName][workflow.id]?.map((item) =>
            workflow.mappedSteps.get(item)
          )
        )
        .flat();

    const prepareMultiselectOptions = (options, workflow) =>
      options.map((step) => (
        <MenuItem
          key={step.id}
          value={step.id}
          classes={{ selected: "selected" }}
        >
          <StyledCheckbox
            checked={isStepChecked(step.id, workflows?.[type][workflow.id])}
            color="success"
          />
          <SelectOptionText>{step.name}</SelectOptionText>
        </MenuItem>
      ));

    return (
      <div>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {data?.map((value) => {
          const workflow = workflowMap.get(value);
          const handleChange = (ev) => {
            handleUpdateState(
              ev.target.value,
              `xtm.workflows.${type}.${workflow.id}`
            );
          };

          return (
            <StepItem key={workflow.id}>
              <StepItemLabel lead>{workflow.name}</StepItemLabel>
              <WhiteSelect
                value={workflows?.[type][workflow.id] || []}
                onChange={handleChange}
                multiple
                sx={{ width: "100%" }}
                renderValue={renderValue(workflow)}
              >
                {prepareMultiselectOptions(
                  differenceBy(
                    workflow.steps,
                    prepareMultiselectValues(workflow),
                    "id"
                  ),
                  workflow
                )}
              </WhiteSelect>
            </StepItem>
          );
        })}
      </div>
    );
  };

processingSteps.propTypes = {
  data: PropTypes.array,
};

processingSteps.defaultProps = {
  data: [],
};
