import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormRow, InputLabel } from "components/global/forms";
import { BorderedSection } from "components/BorderedSection";
import { SectionToggle } from "components/SectionToggle";
import {
  fetchWorkflowsAsync,
  selectModifyWorkflows,
  selectXtmTokenId,
} from "data/features/connectionProfiles/connectionProfileSlice";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  PROCESSING_TYPES,
  processingSteps,
} from "./WorkflowSteps/processingSteps";
import { WorkflowSteps } from "./WorkflowSteps";
import { SelectWorkflowName } from "./WorkflowName/SelectWorkflowName";
import { handleUpdateState } from "../../helpers";

const CustomHeading = () => <span />;

export const Workflows = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tokenID = useSelector(selectXtmTokenId);
  const workflows = useSelector(selectModifyWorkflows);
  const params = useLocation();
  const profileID = Number(new URLSearchParams(params.search).get("id"));

  useEffect(() => {
    if (profileID)
      dispatch(
        fetchWorkflowsAsync({
          id: profileID,
          isProfileId: true,
        })
      );
  }, [profileID]);

  useEffect(() => {
    if (tokenID) {
      handleUpdateState([], "xtm.workflows.workflowIds");
      handleUpdateState({}, "xtm.workflows.preProcessingSteps");
      handleUpdateState({}, "xtm.workflows.ignoredSteps");
      handleUpdateState({}, "xtm.workflows.nonCatToolSteps");
    }
    dispatch(
      fetchWorkflowsAsync({
        id: tokenID,
        isProfileId: false,
      })
    );
  }, [tokenID]);

  if (!workflows.data?.workflows) return null;

  return (
    <BorderedSection
      inner
      aria-label="WorkflowsSection"
      name={t("connectionProfiles.form.xtm.workflows.sectionName")}
    >
      <FormRow contained bottomSpace groupInner>
        <InputLabel lead>
          {t("connectionProfiles.form.xtm.workflows.name.label")}*
        </InputLabel>
        <SelectWorkflowName />
      </FormRow>
      <FormRow contained bottomSpace groupInner sx={{ alignItems: "start" }}>
        <InputLabel lead>
          {t("connectionProfiles.form.xtm.workflows.preProcessingSteps.label")}
        </InputLabel>
        <SectionToggle contentNoPadding customHeading={CustomHeading}>
          <WorkflowSteps
            renderStep={processingSteps(PROCESSING_TYPES.preProcessingSteps)}
          />
        </SectionToggle>
      </FormRow>
      <FormRow contained bottomSpace groupInner sx={{ alignItems: "start" }}>
        <InputLabel lead>
          {t("connectionProfiles.form.xtm.workflows.ignoredSteps.label")}
        </InputLabel>
        <SectionToggle contentNoPadding customHeading={CustomHeading}>
          <WorkflowSteps
            renderStep={processingSteps(PROCESSING_TYPES.ignoredSteps)}
          />
        </SectionToggle>
      </FormRow>
      <FormRow contained bottomSpace groupInner sx={{ alignItems: "start" }}>
        <InputLabel lead>
          {t("connectionProfiles.form.xtm.workflows.nonCatToolSteps.label")}
        </InputLabel>
        <SectionToggle contentNoPadding customHeading={CustomHeading}>
          <WorkflowSteps
            renderStep={processingSteps(PROCESSING_TYPES.nonCatToolSteps)}
          />
        </SectionToggle>
      </FormRow>
    </BorderedSection>
  );
};
