import axios from "axios";

export const fetchAdministrators = ({
  filter = "",
  page = 0,
  limit = 10,
  sort = { field: "email", order: "ASC" },
} = {}) =>
  axios.get("/admin/users", {
    params: {
      filter,
      page: String(page),
      limit: String(limit),
      sort: JSON.stringify(sort),
    },
    headers: {
      "Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

export const addAdministrator = ({
  email = "",
  clientAssigned,
  password = "",
  status = "ACTIVE",
} = {}) =>
  axios.post(
    "/admin/users",
    {
      email,
      password,
      client: clientAssigned,
      status,
    },
    {
      params: {
        client_name: clientAssigned,
      },
    }
  );

export const editAdministrator = ({
  email = "",
  clientAssigned,
  id,
  password = "",
  status = "ACTIVE",
} = {}) => {
  const data = {
    id,
    email,
    client: clientAssigned,
    status,
  };

  if (password.length) {
    data.password = password;
  }

  return axios.put("/admin/users", data);
};

export const fetchAdministratorById = (id) =>
  axios.get(`/admin/users/${id}`, {
    headers: {
      "Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    },
  });
