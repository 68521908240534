import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { TableComponent } from "components";
import { fetchClientsAsync, selectClientsData, selectClientsError } from "data";
import { AddButton } from "components/TableComponent/components";
import {
  selectClientsSort,
  updateSort,
} from "data/features/clients/clientsSlice";
import Container from "components/global/Container";
import { useTranslation } from "react-i18next";
import { clientColumns } from "../helpers";

const ClientsHeading = ({ handleAdd }) => {
  const { t } = useTranslation();

  return (
    <AddButton
      aria-label="AddClientButton"
      onClick={() => handleAdd("/clients/add")}
    >
      {t("clients.add.label")}
    </AddButton>
  );
};

ClientsHeading.propTypes = {
  handleAdd: PropTypes.func.isRequired,
};

export function Clients() {
  const dispatch = useDispatch();
  const data = useSelector(selectClientsData);
  const error = useSelector(selectClientsError);
  const selectSort = useSelector(selectClientsSort);

  const handleFetchClients = useCallback(
    (params) => dispatch(fetchClientsAsync(params)),
    []
  );

  const handleUpdateSort = (sort) => {
    dispatch(updateSort(sort));
  };

  const columns = useMemo(() => clientColumns, [dispatch]);

  useEffect(() => {
    handleFetchClients({ sort: selectSort });
  }, [dispatch]);

  return (
    <>
      {error && JSON.stringify(error)}
      {data && (
        <Container small>
          <TableComponent
            aria-label="Table"
            fetchData={handleFetchClients}
            columns={columns}
            data={data.objects}
            totalItems={data.total_elements}
            renderHeading={ClientsHeading}
            sortObject={selectSort}
            updateSortObject={handleUpdateSort}
          />
        </Container>
      )}
    </>
  );
}
