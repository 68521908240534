import { styled } from "@mui/system";

export const FormFlexRow = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  margin-bottom: 1.5rem;
`;
