import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const NOT_ACTIVE = "INACTIVE";
const ACTIVE = "ACTIVE";

export const StatusCell = ({ value }) => {
  const { t } = useTranslation();
  switch (value) {
    case ACTIVE:
      return t("status.active");
    case NOT_ACTIVE:
      return t("status.inactive");
    default:
      return null;
  }
};

StatusCell.propTypes = {
  value: PropTypes.string.isRequired,
};
