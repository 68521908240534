import { styled } from "@mui/material";
import { CONTAINER_SETTINGS } from "config/theme";

export default styled("div")`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: ${CONTAINER_SETTINGS.spaceX};
  padding-right: ${CONTAINER_SETTINGS.spaceX};
  max-width: ${CONTAINER_SETTINGS.maxWidth};

  ${({ small }) =>
    small &&
    `
    max-width: 1394px;
  `}

  ${({ fluid }) =>
    fluid &&
    `
    max-width: 100%;
  `}
`;

export const InnerSectionContainer = styled("div")`
  width: 100%;
  max-width: calc(550px + ${CONTAINER_SETTINGS.spaceX} * 2);
  margin-left: auto;
  margin-right: auto;
  padding-left: ${CONTAINER_SETTINGS.spaceX};
  padding-right: ${CONTAINER_SETTINGS.spaceX};
  ${({ big }) =>
    big &&
    `
    max-width: 707px;
  `}

  ${({ noPadding }) =>
    noPadding &&
    `
    padding: 0;
  `}

  ${({ withPadding }) =>
    withPadding &&
    `
    padding: 0 3.4rem;
  `}
`;
