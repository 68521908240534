import React from "react";
import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { Tooltip } from "components/Tooltip";
import { ReactComponent as EditIcon } from "assets/edit-list-item.svg";
import { ReactComponent as CloneIcon } from "assets/connection-profile-clone.svg";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ActionsWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  button {
    margin-right: 0.5rem;
  }
`;

export const ConnectionProfilesActionCell = (tableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (pathname) => () => {
    // eslint-disable-next-line react/destructuring-assignment
    const searchParamValue = tableProps?.row?.values?.id;

    navigate({
      pathname,
      search: searchParamValue
        ? `?${createSearchParams({ id: searchParamValue })}`
        : undefined,
    });
  };

  return (
    <ActionsWrapper>
      <Tooltip
        title={t("connectionProfiles.actions.edit")}
        arrow
        placement="bottom"
      >
        <IconButton
          aria-label="EditProfileButton"
          onClick={handleClick("/connection-profiles/edit")}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t("connectionProfiles.actions.clone")}
        arrow
        placement="bottom"
      >
        <IconButton
          aria-label="CloneProfileButton"
          onClick={handleClick("/connection-profiles/clone")}
        >
          <CloneIcon />
        </IconButton>
      </Tooltip>
    </ActionsWrapper>
  );
};
