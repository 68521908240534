import {
  DateCell,
  SelectRowCell,
  SelectAllRowCell,
} from "components/TableComponent/cells";
import { EventLogActionsCell } from "components/TableComponent/cells/EventLogActionsCell";
import { MessageCell } from "components/TableComponent/cells/MessageCell";
import i18n from "config/i18n";

const { t } = i18n;

export const eventLogsColumns = [
  {
    Header: SelectAllRowCell,
    accessor: "id",
    width: "auto",
    disableSortBy: true,
    Cell: SelectRowCell,
  },
  {
    Header: t("column.eventLogs.type"),
    accessor: "type",
    width: "74px",
  },
  {
    Header: t("column.eventLogs.message"),
    accessor: "message",
    width: "658px",
    Cell: MessageCell,
  },
  {
    Header: t("column.eventLogs.connectionProfileName"),
    accessor: "connection_profile_name",
    width: "297px",
  },
  {
    Header: t("column.eventLogs.eventDate"),
    accessor: "created_date",
    width: "160px",
    Cell: DateCell,
  },
  {
    Header: t("column.eventLogs.actions"),
    accessor: "actions",
    width: "50px",
    disableSortBy: true,
    Cell: EventLogActionsCell,
  },
];
