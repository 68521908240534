const API_URL = "REACT_APP_API_URL";

export class EnvService {
  static getApiUrl() {
    if (window.env?.ENVIRONMENT === "local") {
      return process.env?.[API_URL] ?? "";
    }
    return window.env?.API_URL;
  }
}
