import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "components";
import { FormRow, InputLabel } from "components/global/forms";
import { MenuItem, WhiteSelect } from "components/Select";
import ErrorIcon from "@mui/icons-material/Error";
import { fetchTemplates } from "data/features/connectionProfiles/connectionProfileApi";
import { selectModifyForm } from "data";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { handleUpdateState } from "../helpers";

export const XTMTemplate = () => {
  const [templates, setTemplates] = useState([]);
  const { t } = useTranslation();
  const form = useSelector(selectModifyForm);
  const params = useLocation();
  const profileID = Number(new URLSearchParams(params.search).get("id"));

  useEffect(() => {
    fetchTemplates(
      {
        tempCredentialId:
          form.xtm.tokenID !== null ? form.xtm.tokenID : profileID,
        scope: form.xtm.hotFolders.templateType,
        customerIds: form.xtm.customer,
      },
      form.xtm.tokenID === null
    ).then((response) => {
      setTemplates(response.data);
      const responseIds = response.data.map((template) => template.id);
      if (responseIds.includes(!form.xtm.hotFolders.xtmTemplate)) {
        handleUpdateState(null, "xtm.hotFolders.xtmTemplate");
      }
    });
  }, [
    form.xtm.hotFolders.templateType,
    form.xtm.customer,
    profileID,
    form.xtm.tokenID,
  ]);

  const handleChangeXtmTemplate = (ev) => {
    handleUpdateState(ev.target.value, "xtm.hotFolders.xtmTemplate");
  };

  return (
    <FormRow contained group bottomSpaceLow sx={{ position: "relative" }}>
      <InputLabel lead>
        {t("connectionProfiles.form.xtm.template.label")}*
      </InputLabel>
      <WhiteSelect
        value={form.xtm.hotFolders.xtmTemplate}
        onChange={handleChangeXtmTemplate}
      >
        {templates.length > 0 &&
          templates.map((template) => (
            <MenuItem key={template.id} value={template.id}>
              {template.name}
            </MenuItem>
          ))}
      </WhiteSelect>
      <Tooltip
        title={t("connectionProfiles.form.xtm.template.tooltip")}
        arrow
        placement="top"
      >
        <ErrorIcon
          sx={{
            color: "#FF6347",
            fontSize: "1.8rem",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: "1rem",
          }}
        />
      </Tooltip>
    </FormRow>
  );
};
