import React from "react";
import PropTypes from "prop-types";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import { ClickAwayListener } from "@mui/material";
import { styled } from "@mui/system";
// import { ReactComponent as CalendarArrow } from "assets/calendar-arrow.svg";
import "./custom-calendar.css";
import { defaultStaticRanges } from "./helpers";

const Wrapper = styled("div")`
  height: 260px;
`;

// const StyledCalendarArrow = styled(CalendarArrow)`
//   position: absolute;
//   top: 4.8px;
//   left: 237px;
// `;

export const CalendarRange = ({ value, handleSetValue, handleClickAway }) => (
  <ClickAwayListener onClickAway={handleClickAway}>
    <Wrapper aria-label="CalendarRange">
      {/* <StyledCalendarArrow /> */}
      <DateRangePicker
        onChange={handleSetValue}
        months={2}
        direction="horizontal"
        maxDate={new Date()}
        ranges={[value.selection]}
        rangeColors={["#00A8EB"]}
        dragSelectionEnabled={false}
        weekStartsOn={1}
        showMonthAndYearPickers={false}
        monthDisplayFormat="MMMM yyy"
        showSelectionPreview={false}
        inputRanges={[]}
        staticRanges={defaultStaticRanges}
      />
    </Wrapper>
  </ClickAwayListener>
);

CalendarRange.propTypes = {
  value: PropTypes.object.isRequired,
  handleSetValue: PropTypes.func.isRequired,
  handleClickAway: PropTypes.func.isRequired,
};
